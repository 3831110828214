import { Form } from 'formsy-semantic-ui-react';
import _ from 'lodash';
import moment from 'moment/moment';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
  Segment,
} from 'semantic-ui-react';
import Can from '../../../abilities/Can';
import MilestoneDetail from "../../../components/core/invoices/MilestoneDetail";
import MilestoneForm from '../../../components/core/invoices/MilestoneForm';
import MilestoneModal from '../../../components/core/invoices/MilestoneModal';
import MilestoneTable from '../../../components/core/invoices/MilestoneTable';
import ProjectMilestoneForm from '../../../components/core/invoices/ProjectMilestoneForm';
import IconButton from '../../../components/shared/buttons/IconButton';
import ReusableHeaderWithHelp from '../../../components/shared/ReusableHeaderWithHelp';
import {
  startApproveMilestone,
  startClearMilestones,
  startCreateMilestone,
  startDeleteMilestone,
  startDownloadMilestoneList,
  startGetMilestone,
  startGetMilestoneList,
  startRejectMilestone,
  startSetBilledStatus,
  //AM-002: "Delete Milestone" button - BEGIN
  startUnapproveMilestone,
} from '../../../store/actions/core/invoice/milestone';
import {
  resetSearch,
  startFilterProjects,
} from '../../../store/actions/core/project/project';
import { resetSearchManager, startGetProject_manager } from '../../../store/actions/core/project/project_manager';
import { startGetCompanies } from '../../../store/actions/shared/company';
import { startGetCurrencies } from "../../../store/actions/shared/currency";
import { clearSalesLevels, startGetSalesLevelsForProject } from "../../../store/actions/shared/sales_level";
import constants from '../../../utils/constants';
import { isUserInGroup } from '../../../utils/functionUtils';

//AM-002: "Delete Milestone" button - BEGIN
import { amIFullAdmin, amISuperAdmin } from "../../../utils/functionUtils";
//AM-002: "Delete Milestone" button - END

const ProjectStatuses = [
  { value: "all", text: "All" },
  { value: "active", text: "Active" },
  { value: "inactive", text: "Inactive" },
  { value: "pending", text: "Pending" },
  { value: "rejected", text: "Rejected" },
];

const monthStr = [
  'All',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const milestoneColumns = [
  { id: 'id', name: 'Invoice Id', type: 'String' },
  { id: 'code', name: 'Project Code', type: 'String' },
  { id: 'customer_PO', name: 'Customer PO', type: 'String' },
  { id: 'planned_date', name: 'Planned Date', type: 'String' },
  { id: 'competence_period_start', name: 'Competence Start', type: 'String' },
  { id: 'competence_period_end', name: 'Competence End', type: 'String' },
  { id: 'tot_billed', name: 'Tot. Billed', type: 'String' },
  { id: 'invoiced', name: 'Invoiced', type: 'boolean' },
  { id: 'tot_invoiced', name: 'Tot. Invoiced', type: 'String' },
  { id: 'milestone_status_id', name: 'Status', type: 'status' },
  { id: 'milestone_description', name: 'Description', type: 'String' }
];

const milestoneColumnsPM = [
  { id: 'code', name: 'Project Code', type: 'String' },
  { id: 'project_description', name: 'Project Description', type: 'String' },
  { id: 'project_type_name', name: 'Project Type', type: 'String' },
  { id: 'project_status_name', name: 'Project Status', type: 'String' },
  { id: 'invoice_type_name', name: 'Invoice Type', type: 'String' },
  { id: 'billing_client', name: 'Billing Client', type: 'String' }
];

const monthOptions = Array.from({ length: 13 }, (v, k) => {
  const month = k + 1;
  return { key: k, value: month, text: monthStr[k] };
});

const milestoneSummary = [
  {
    code: 'Project Code',
    project_type_name: "Project Type",
    invoice_type_name: "Invoice Type",
    percentage: "Percentage",
  },
  {
    project_description: 'Description',
    project_manager: 'Project Manager',
    billing_client: "Billing Client",
  },
  {
    tot_invoiced_activities: 'Total Invoiced Activities',
    tot_order_value: "Total Order Value",
    tot_billable_activities: 'Total Billable Activities', //tot_order_value-tot_invoiced_activities
  },
  {
    tot_invoiced_expenses: 'Total Invoiced Expenses',
    billable_expenses: "Billable Expenses",
    tot_billable_expenses: 'Total Billable Expenses', //billable_expenses-tot_invoiced_expenses
  },
  {
    tot_invoiced: "TOTAL INVOICED",
    tov_be: "TOTAL ORDER VALUE + BILLABLE EXPENSES",
    tot_billable: "TOTAL BILLABLE"
  }
];

class InvoicesContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading_milestone: false,
      showFormModal: false,
      showDetail: false,
      summary: false,
      openModal: false,
      isPM: false,
      isAR: false,

      //AM-002: "Delete Milestone" button - BEGIN
      isSuperAdmin: false,
      //AM-002: "Delete Milestone" button - END

      user_groups: this.props.user_groups,
      selectedProject: "",
      values: {
        project_manager: "",
        project_manager_id: "",
        project_code: "",
        month: "",
        year: "",
        invoiced: "",
        project_status: "all",
      },
      exportValues: {
        year: "",
        month: "",
        company: "",
        invoiced: "",
        status: "",
      },
      newMilestoneValues: { attached: "" },
      tableType: "project_manager",
      sales_levels: [],
      error: "",
      attached: ["attached_none", "attached_timesheet", "attached_client", "attached_expense", "attached_other"]
    };
  }


  componentDidMount() {
    this.props.getCurrencies();
    this.resetSearch();
    let isAr = false;
    let isPm = false;

    //AM-002: "Delete Milestone" button - BEGIN
    let isSuperAdmin = false;
    let isFullAdmin = false;
    //AM-002: "Delete Milestone" button - END

    // console.log('PROPS INVOICES. is_project_manager: ', this.props.user_details && (this.props.user_details[0].is_project_manager === 1))

    if (isUserInGroup(this.props.user_groups, 'ia-accountreceivable') || isUserInGroup(this.props.user_groups, 'ia-accountreceivable-admin')) {
      isAr = true;
    } else if (this.props.user_details && (this.props.user_details[0].is_project_manager === 1)) {
      isPm = true;
    }

    //AM-002: "Delete Milestone" button - BEGIN
    if (amISuperAdmin(this.props.user_groups))
      isSuperAdmin = true;

    if (amIFullAdmin(this.props.user_groups))
      isFullAdmin = true;
    //AM-002: "Delete Milestone" button - END

    this.setState({
      isAR: isAr,
      isPM: isPm,

      //AM-002: "Delete Milestone" button - BEGIN
      isSuperAdmin: isSuperAdmin,
      isFullAdmin: isFullAdmin,
      //AM-002: "Delete Milestone" button - END

      values: {
        ...this.state.values,
        project_manager: isPm ? this.props.name : '',
        project_manager_id: isPm ? this.props.unique_id : '',
        year: moment().format('YYYY'),
        month: 1
      }
    });

    if (isPm) {
      let searchParams = {};
      searchParams['project_manager'] = this.props.unique_id;
      searchParams['year'] = moment().format('YYYY');
      this.props.getMilestoneList(searchParams);
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user_groups !== prevState.user_groups) {
      //console.log(nextProps.user_groups.find(u => u === 'ia-projectmanager'));
      return {
        user_groups: nextProps.user_groups,
      };
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user_groups !== this.props.user_groups) {
      let isAr = false;
      let isPm = false;

      //AM-002: "Delete Milestone" button - BEGIN
      let isSuperAdmin = false;
      let isFullAdmin = false;
      //AM-002: "Delete Milestone" button - END

      if (isUserInGroup(this.props.user_groups, 'ia-accountreceivable') || isUserInGroup(this.props.user_groups, 'ia-accountreceivable-admin')) {
        isAr = true;
      } else if (this.props.user_details && (this.props.user_details[0].is_project_manager === 1)) {
        isPm = true;
      }

      //AM-002: "Delete Milestone" button - BEGIN
      if (amISuperAdmin(this.props.user_groups))
        isSuperAdmin = true;

      if (amIFullAdmin(this.props.user_groups))
        isFullAdmin = true;
      //AM-002: "Delete Milestone" button - END

      //Perform some operation here
      this.setState({
        user_groups: this.props.user_groups,
        isAR: isAr,
        isPM: isPm,

        //AM-002: "Delete Milestone" button - BEGIN
        isSuperAdmin: isSuperAdmin,
        isFullAdmin: isFullAdmin,
        //AM-002: "Delete Milestone" button - END

        values: {
          ...this.state.values,
          project_manager: isPm ? this.props.name : '',
          project_manager_id: isPm ? this.props.unique_id : '',
          year: moment().format('YYYY'),
          month: 1
        }
      });
    }

  }

  resetSearch = () => {
    this.props.clearMilestones();
    if (!this.state.isPM) {
      this.setState({
        values: {
          project_manager: "",
          project_manager_id: "",
          project_code: "",
          month: "",
          year: "",
          invoiced: "",
          project_status: "all",
        }
      });
      this.props.resetSearchManager();
    } else {
      this.setState(prevState => ({
        values: {
          ...prevState.values,
          project_code: "",
          month: "",
          year: "",
          invoiced: "",
          project_status: "all",
        }
      }));
    }
    this.props.resetSearch();
  }

  resetExportSearch = () => {
    this.setState({
      exportValues: {
        year: "",
        month: "",
        company: "",
        invoiced: "",
        status: "",
      }
    })
  }

  renderForm() {
    return (
      <Grid.Column width={10}>
        <Grid.Row>
          <ProjectMilestoneForm
            values={this.state.newMilestoneValues}
            summary={this.state.summary}
            options={this.props.options}
            loadings={this.props.loadings}
            loading_milestone={this.state.loading_milestone}
            onCancel={this.onCancel}
            onNext={this.onNext}
            onEdit={this.onEdit}
            onDateChange={this.onDateChange}
            onSubmit={this.onSubmit}
            handleBack={this.handleBack}
            sales_levels={this.state.sales_levels}
            handleChange={this.handleChangeNewMilestone}
            handleSearchChange={this.handleSearchChange}
            handleChangeProject_code={this.handleChangeProject_codeNewMilestone}
            onChangeAmountDays={this.onChangeAmountDays}
            error={this.state.error}

          />
        </Grid.Row>
      </Grid.Column>
    );
  }

  onChangeAmountDays = (e, data, sl, i) => {
    //console.log("e, data, sl , index", e, data, sl, i, this.state.sales_levels);
    let sales_levels = this.state.sales_levels.length === 0 ? this.props.options.salesLevels.map(s => Object.assign({}, s)) : this.state.sales_levels.map(s => Object.assign({}, s));
    sales_levels[i].name = sl.value;
    if (sales_levels[i].hasOwnProperty("text")) delete sales_levels[i].text;
    if (sales_levels[i].hasOwnProperty("key")) delete sales_levels[i].key;
    if (sales_levels[i].hasOwnProperty("enabled")) delete sales_levels[i].enabled;
    if (sales_levels[i].hasOwnProperty("value")) delete sales_levels[i].value;
    sales_levels[i].id = sl.key;
    if (data.name === "single_amount" && data.value !== "") {
      sales_levels[i].single_amount = data.value;
    } else if (data.name === "single_amount" && data.value === "") {
      sales_levels[i].single_amount = "";
    } else if (data.name === "days_number" && data.value !== "") {
      sales_levels[i].days_number = data.value;
    } else if (data.name === "days_number" && data.value === "") {
      sales_levels[i].days_number = "";
    }
    this.setState({ ...this.state.sales_levels, sales_levels: sales_levels, error: "" });
    //console.log(this.state.sales_levels);

  };

  handleChangeNewMilestone = (e, data) => {

    //console.log("DATA ", data, data.value, this.state.newMilestoneValues);
    if (data.value === "" && data.checked === undefined) {
      data.value = "";
    } else if (data.checked !== undefined) data.value = data.checked;

    let error = "";
    let values = Object.assign({}, this.state.newMilestoneValues);
    if (data.options) {
      let data_id = data.options.find(o => o.value === data.value).key;
      let name_id = data.name + "_id";
      values[name_id] = data_id;
      values[data.name] = data.value;
    } else if (this.state.attached.includes(data.name) && (values.attached === "" || values.attached === "")) {
      if (data.name !== "attached_other" && data.checked) {
        values.attached = data.name;
      }
      else if (data.name === "attached_other") {
        values.attached = data.value;
        values[data.name] = data.value;
      }
    } else if (this.state.attached.includes(data.name) && values.attached !== "" && values.attached !== "" && !data.checked && data.name !== "attached_other") {
      values.attached = "";
    } else {
      if (data.name === "attached_other") values.attached = data.value;
      values[data.name] = data.value;
    }

    this.setState({
      newMilestoneValues: values,
      error: error
    });

    //console.log(this.state.newMilestoneValues);
  };

  onCancel = () => {
    this.props.clearSalesLevels();
    this.setState({
      newMilestoneValues: { attached: "" },
      sales_levels: [],
      error: ""
    })
  }

  onNext = (values) => {
    //console.log("ON NEXT ", this.state.sales_levels);
    let dates = Object.assign({}, values);
    dates.competence_period_start = moment.utc(values.competence_period_start).format("YYYY-MM-DD");
    dates.competence_period_end = moment.utc(values.competence_period_end).format("YYYY-MM-DD");
    dates.planned_date = moment.utc(values.planned_date).format("YYYY-MM-DD");
    let vals = Object.assign({}, this.state.newMilestoneValues);
    vals.total_activity_amount = parseFloat(vals.total_activity_amount);
    if (vals.hasOwnProperty("expenses")) {
      vals.expenses = parseFloat(vals.expenses);
      vals.tot_billed = parseFloat(vals.total_activity_amount) + parseFloat(vals.expenses);
    } else {
      vals.tot_billed = parseFloat(vals.total_activity_amount);
    }
    let result = _.merge(vals, dates);
    let sls = this.state.sales_levels.filter(a => a.single_amount);
    let sum = 0;
    for (let s in sls) {
      sum += parseFloat(sls[s].single_amount);
    }
    sum = sum.toFixed(2);
    //console.log( "SUM ", sum);
    if (sls.length === 0) {
      this.setState({
        newMilestoneValues: result,
        summary: false,
        error: "You need to add at least one amount value to your sales levels."
      });
    } else if (sum !== vals.total_activity_amount.toFixed(2)) {
      this.setState({
        newMilestoneValues: result,
        summary: false,
        error: "The sum of sale levels is not equal to the Total Activity Amount."
      });
    } else if (vals.hasOwnProperty("trance") && (parseFloat(vals.trance) < 0 || parseFloat(vals.trance) > 100)) {
      this.setState({
        newMilestoneValues: result,
        summary: false,
        error: "The billing trance must be a percentage (a value between 0 and 100)"
      });
    } else {
      result.sales_level = sls;
      this.setState({
        newMilestoneValues: result,
        summary: true,
        error: ""
      });
    }

  }

  onEdit = () => {
    this.setState({
      summary: false
    })
  }

  onSubmit = () => {
    this.setState({
      loading_milestone: true
    })
    const onFailure = res => {
      this.setState({
        loading_milestone: false
      })
      let description = "";
      //console.log("onError", res.response);
      if (res.response.status === 406)
        description = res.response.data.error;
      else
        description = "The milestone hasn't been created. Try Again";

      toast(
        {
          title: "Error",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left"
        });
    };

    const onSuccess = res => {
      //console.log("onSuccess", res, res.id);
      toast(
        {
          title: "Success",
          description: "The milestone has been created",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left"
        }
      );
      this.setState({
        showFormModal: false,
        summary: false,
        loading_milestone: false,
      })
      this.onCancel();
    };
    let params = Object.assign({}, this.state.newMilestoneValues);
    if (params.hasOwnProperty("trance")) params.trance = parseFloat(params.trance);
    if (params.hasOwnProperty("receipt")) params.receipt = parseInt(params.receipt);
    if (params.hasOwnProperty("attached_none")) delete params.attached_none;
    if (params.hasOwnProperty("attached_timesheet")) delete params.attached_timesheet;
    if (params.hasOwnProperty("attached_client")) delete params.attached_client;
    if (params.hasOwnProperty("attached_expense")) delete params.attached_expense;
    if (params.hasOwnProperty("attached_other")) delete params.attached_other;
    if (params.hasOwnProperty("attached") && params.attached === "") delete params.attached;
    if (params.attached === "attached_none") params.attached = "None";
    if (params.attached === "attached_timesheet") params.attached = "Time Sheet";
    if (params.attached === "attached_client") params.attached = "Client";
    if (params.attached === "attached_expense") params.attached = "Expense";
    for (let s in params.sales_level) {
      params.sales_level[s].single_amount = parseFloat(params.sales_level[s].single_amount);
      if (params.sales_level[s].hasOwnProperty("days_number")) params.sales_level[s].days_number = parseFloat(params.sales_level[s].days_number);
    }
    delete params.project_code;
    delete params.currency;
    params.currency = params.currency_id;
    delete params.currency_id;
    //console.log('newMilestone ', params);
    this.props.createMilestone(params, onSuccess, onFailure);

  };

  handleBack = () => {
    this.props.clearSalesLevels();
    this.setState({
      showFormModal: false,
      summary: false,
      newMilestoneValues: { attached: "" }
    })
  }

  handleProjectDetailBack = () => {
    this.getMilestones();
    this.setState({
      showDetail: false,
      selectedProject: ""
    })
  }

  handleDetailBack = () => {
    //this.getMilestones();
    this.props.getMilestoneList({ project_code: this.state.selectedProject });
    this.setState({
      showDetail: false,
      tableType: "project",
    })
  }


  onClickDetail = rowData => {
    //console.log(rowData);
    this.props.getMilestone(rowData.id);
    this.setState((prevState) => ({
      ...prevState,
      showDetail: true,
      selectedProject: rowData.code
    })
    )
  };

  onClickProjectDetail = rowData => {
    //console.log(rowData);
    this.setState({
      selectedProject: rowData.code,
      tableType: "project"
    })
    this.props.getMilestoneList({ project_code: rowData.code });
  }

  onClose = () => {
    this.resetExportSearch();
    this.setState({
      openModal: false,
    });
  }

  handleChange = (e, { name, value }) => {
    //console.log("[handleChange] ", name, value, this.state.values);
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
    }));

  };

  handleChangeExport = (e, { name, value }) => {
    //console.log("[handleChangeExport] ", name, value, this.state.exportValues);
    this.setState(prevState => ({
      exportValues: {
        ...prevState.exportValues,
        [name]: value,
      },
    }));

  };

  openExport = () => {
    this.setState({ openModal: true });
    this.props.getCompanies();
  }

  handleSearchChange = (e, { name }) => {
    //console.log("[handleSearchChange]", name);
    const query = e.target.value;
    if (query.length > 2) {
      switch (name) {
        case 'project_code':
          this.props.getProjects(query, this.props.unique_id);
          break;
        case 'project_manager':
          this.props.getProjectManagers();
          break;
        default:
          break;
      }
    }
  };

  handleChangeProject_code = pid => {
    //console.log("[handleChangeProject_code] ", pid);
    let pcode = this.props.projects.find(p => p.key === pid).text;
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        project_code: pcode,
      },
    }));
  };

  handleChangeProject_codeNewMilestone = pid => {
    //console.log("[handleChangeProject_codeNewMilestone] ", pid);
    let pcode = this.props.projects.find(p => p.key === pid).text;
    let project_type = this.props.options.project_code.find(p => p.key === pid).type_code;
    let invoice_type = this.props.options.project_code.find(p => p.key === pid).invoice_type_code;
    this.props.getSalesLevels(pid);
    this.setState(prevState => ({
      newMilestoneValues: {
        ...prevState.newMilestoneValues,
        project_code: pcode,
        project_id: pid,
        project_type: project_type,
        invoice_type: invoice_type
      },
    }));
  };

  handleChangeProject_manager = data => {
    //console.log("[handleChangeProject_manager] ", data);
    let pm_unique_id = this.props.project_managers.find(p => p.text === data).key;

    this.setState(prevState => ({
      values: {
        ...prevState.values,
        project_manager: data,
        project_manager_id: pm_unique_id
      },
    }));
  };

  checkSearchFields = () => {
    let disabled = false;
    if (this.state.values.year !== "" && this.state.values.month === "")
      disabled = true;
    else if (this.state.values.year === "" && this.state.values.month !== "")
      disabled = true;
    else if (_.every(this.state.values, v => v === ""))
      disabled = true;
    else if (this.state.values.project_manager !== "" && this.state.values.year === "" && this.state.values.month === "")
      disabled = true;
    else if (this.state.values.project_manager === "" && this.state.values.project_code === "")
      disabled = true;
    return disabled;
  }

  getMilestones = () => {
    let searchParams = Object.assign({}, this.state.values);
    let tableType = "project";
    for (let v in searchParams) {
      if (searchParams[v] === "")
        delete searchParams[v];
    }
    if (searchParams.hasOwnProperty("month") && searchParams.month === 1)
      delete searchParams.month;
    if (searchParams.hasOwnProperty("month") && searchParams.month !== 1)
      searchParams.month = searchParams.month - 1;
    if (searchParams.hasOwnProperty("year")) searchParams.year = parseInt(searchParams.year);
    if (searchParams.hasOwnProperty("invoiced") && searchParams.invoiced === "All") delete searchParams.invoiced;
    if (searchParams.hasOwnProperty("invoiced") && searchParams.invoiced !== "All") searchParams.invoiced = parseInt(searchParams.invoiced);
    if (searchParams.hasOwnProperty("project_manager_id")) {
      searchParams.project_manager = searchParams.project_manager_id;
      delete searchParams.project_manager_id;
    }
    if (searchParams.hasOwnProperty("project_manager")) tableType = "project_manager";
    this.setState({
      tableType: tableType,
    });
    //console.log("searchParams ", searchParams);
    this.props.getMilestoneList(searchParams);

  };


  exportSpreadsheet = () => {
    const onFailure = res => {
      console.log("onError", res);
      toast(
        {
          title: "Invoice",
          description: "Error exporting spreadsheet. Please log out and back in then try again.",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    }
    const onSuccess = res => {
      console.log("onSuccess", res);
      if (res === '')
        toast(
          {
            title: "Invoice",
            description: "No milestones found.",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
    };

    toast(
      {
        title: "Invoice",
        description: "Report is being generated, please wait.",
        type: "info",
        time: constants.TOAST_WARNING_TIMEOUT,
        animation: 'fly left'
      }
    );

    let searchParams = Object.assign({}, this.state.exportValues);
    searchParams.month = searchParams.month - 1;
    if (searchParams.month === 0) delete searchParams.month;
    if (searchParams.invoiced !== "All") searchParams.invoiced = parseInt(searchParams.invoiced);
    else delete searchParams.invoiced;
    if (searchParams.status !== "All") searchParams.status = parseInt(searchParams.status);
    else delete searchParams.status;

    searchParams.year = parseInt(searchParams.year);
    for (let v in searchParams) {
      if (searchParams[v] === "")
        delete searchParams[v];
    }
    //console.log(searchParams, searchParams.invoiced);
    const company = this.props.company.find(x => x.key === searchParams.company)
    searchParams.company_name = !company ? '' : company.text //Just in case
    this.onClose();
    this.props.getMilestoneListDownload(searchParams, onSuccess, onFailure);
  }


  handleDelete = (id) => {
    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Invoice",
          description: "Error deleting the milestone. Try Again",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.handleDetailBack();
    }
    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Invoice",
          description: "Milestone successfully deleted",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.handleDetailBack();
    };
    this.props.deleteMilestone(id, onSuccess, onFailure);
  }

  handleApprove = (id, note) => {
    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Invoice",
          description: "Error approving the milestone. Try Again",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    }
    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Invoice",
          description: "Milestone successfully approved",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
    };
    this.props.approveMilestone(id, note, onSuccess, onFailure);
  }

  handleBill = (id) => {
    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Invoice",
          description: "Error billing the milestone. Try Again",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    }
    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Invoice",
          description: "Milestone successfully billed",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
    };
    this.props.billMilestone(id, onSuccess, onFailure);
  }

  handleReject = (id, note) => {
    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Invoice",
          description: "Error rejecting the milestone. Try Again",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    }
    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Invoice",
          description: "Milestone successfully rejected",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
    };
    this.props.rejectMilestone(id, note, onSuccess, onFailure);
  }

  onNewMilestone = () => {
    this.resetSearch();
    this.setState({
      showFormModal: true,
      summary: false
    })
  }

  //AM-002: "Delete Milestone" button - BEGIN
  handleUnapprove = (id, note) => {
    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Invoice",
          description: "Error removing the approval. Try Again",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    }
    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Invoice",
          description: "Approval successfully removed",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
    };

    this.props.unapproveMilestone(id, note, onSuccess, onFailure);
  }
  //AM-002: "Delete Milestone" button - END

  render() {
    //console.log(this.state.user_groups ? this.state.user_groups : "problem");

    return (
      <Container>
        <ReusableHeaderWithHelp title="Invoices" link="/help/invoices" />

        <Can I="invoices:Read" a="invoice">
          <MilestoneModal
            loading_export={this.props.loading_export}
            open={this.state.openModal}
            onClose={this.onClose}
            handleChange={this.handleChangeExport}
            monthChoose={monthOptions}
            company={this.props.company}
            company_loading={this.props.loading_company}
            values={this.state.exportValues}
            onSubmit={this.exportSpreadsheet}
            resetExportSearch={this.resetExportSearch}
            milestoneStatus={this.props.milestone_status_type}
          />
          {this.state.showFormModal ? this.renderForm() : this.state.showDetail ?

            //AM-002: "Delete Milestone" button - BEGIN
            //<MilestoneDetail handleDelete ={this.handleDelete} handleApprove={this.handleApprove} handleReject={this.handleReject} handleBill={this.handleBill} loading={this.props.loading_milestone} values={this.props.milestone ? this.props.milestone : {}} handleDetailBack={this.handleDetailBack}/> :
            <MilestoneDetail handleDelete={this.handleDelete} handleApprove={this.handleApprove} handleReject={this.handleReject} handleBill={this.handleBill} loading={this.props.loading_milestone} values={this.props.milestone ? this.props.milestone : {}} handleDetailBack={this.handleDetailBack}
              isAR={this.state.isAR} isPM={this.state.isPM} isSuperAdmin={this.state.isSuperAdmin} isFullAdmin={this.state.isFullAdmin} handleUnapprove={this.handleUnapprove} /> :
            //AM-002: "Delete Milestone" button - END

            <div>
              <Segment clearing>
                <Dimmer active={this.props.loading || this.props.loading_export} inverted>
                  <Loader content='Loading' />
                </Dimmer>

                <Can I="invoices:Create" a="new-invoice">
                  <IconButton
                    icon="plus"
                    label="New Milestone"
                    onClick={this.onNewMilestone}
                    floated="right"
                  />
                </Can>

                <Can I="invoices:Read" a="export-invoices">
                  <IconButton
                    loading={this.props.loading_export}
                    icon="chart bar"
                    label="Get Report"
                    onClick={this.openExport}
                    floated='right'
                  />
                </Can>
                <Header>{'Search Milestones'}</Header>
                <div style={{ marginTop: "2rem", marginLeft: "2rem", marginRight: "2rem" }}>
                  <Grid>
                    <Grid.Row>
                      <Form>
                        <Form.Group widths="equal">
                          <Form.Input
                            placeholder="Year"
                            name="year"
                            label="Creation Date Year"
                            value={this.state.values.year}
                            onChange={this.handleChange}
                            maxLength={4}
                          />
                          <Form.Select
                            placeholder="Month"
                            name="month"
                            label="Creation Date Month"
                            value={this.state.values.month}
                            options={monthOptions}
                            onChange={this.handleChange}
                          />
                          <Form.Select
                            options={this.props.projects}
                            loading={this.props.loadings.project_code}
                            placeholder="Search by project..."
                            value={this.state.values.project_code}
                            text={this.state.values.project_code}
                            label="Project Code"
                            name="project_code"
                            search={(options) => options}
                            onSearchChange={this.handleSearchChange}
                            onChange={(e, { value }) =>
                              this.handleChangeProject_code(value)
                            }
                            disabled={this.state.values.hasOwnProperty("project_manager") && this.state.values.project_manager !== ""}
                          />
                          {!this.state.isPM ?
                            <Form.Select
                              name="project_manager"
                              label="Project Manager"
                              options={this.props.project_managers}
                              //search={(options) => options}
                              search={true}
                              loading={this.props.loading_project_managers}
                              placeholder="Search by PM..."
                              onSearchChange={this.handleSearchChange}
                              onChange={(e, { value }) =>
                                this.handleChangeProject_manager(value)
                              }
                              disabled={this.state.values.hasOwnProperty("project_code") && this.state.values.project_code !== ""}
                            />
                            :
                            <Form.Input
                              name="project_manager"
                              label="Project Manager"
                              value={this.state.values.project_manager}
                              onChange={this.handleChange}
                              readOnly
                            />
                          }
                          <Form.Select
                            name="invoiced"
                            label="Invoiced"
                            values={this.state.values.invoiced}
                            search
                            placeholder="Search by invoiced ..."
                            options={[
                              {
                                key: 0,
                                value: "All",
                                text: 'All',
                              },
                              {
                                key: 1,
                                value: "1",
                                text: 'Yes',
                              },
                              {
                                key: 2,
                                value: "0",
                                text: 'No',
                              }
                            ]}
                            onChange={this.handleChange}
                          />
                          <Form.Select
                            placeholder="Filter by..."
                            name="project_status"
                            label="Project Status"
                            options={this.props.options.project_statuses}
                            onChange={this.handleChange}
                            value={this.state.values.project_status}
                          />
                        </Form.Group>

                        <IconButton
                          icon="search"
                          label="Search"
                          onClick={this.getMilestones}
                          floated="left"
                          disabled={this.checkSearchFields()}
                          loading={this.props.loading}
                        />
                        <Button className='cancel' onClick={this.resetSearch} floated="left"
                          disabled={_.every(this.state.values, v => v === "")}>Cancel</Button>

                      </Form>
                    </Grid.Row>
                  </Grid>
                </div>
              </Segment>
              {this.props.milestones && this.props.milestones.length > 0 ?

                <Segment>
                  {this.state.tableType === "project" ? (

                    <Grid.Row>
                      <Button icon className="cancel" onClick={this.handleProjectDetailBack} style={{ marginLeft: "1rem", marginBottom: "2rem" }}><Icon name="arrow left" /></Button>
                      <Header>Project Summary</Header>
                      <MilestoneForm
                        columns={milestoneSummary}
                        values={this.props.milestones[0]}
                      />
                    </Grid.Row>) : ""}
                  <Header>{this.state.tableType === "project" ? "Milestones" : "Projects with milestones"}</Header>
                  <MilestoneTable
                    columns={this.state.tableType === "project" ? milestoneColumns : milestoneColumnsPM}
                    milestones={this.props.milestones}
                    onClick={this.state.tableType === "project" ? this.onClickDetail : this.onClickProjectDetail}
                  /></Segment> : ""}
            </div>}

        </Can>


      </Container>
    );
  }
}

const mapStateToProps = state => ({
  name: state.authReducer.name,
  user: state.authReducer.user,
  user_groups: state.authReducer.user_groups,
  loading: state.milestoneReducer.loading,
  unique_id: state.authReducer.unique_id,
  companies: state.milestoneReducer.companies,
  milestones: state.milestoneReducer.milestones,
  milestone: state.milestoneReducer.milestone,
  loading_milestone: state.milestoneReducer.loading_milestone,
  loading_project_managers: state.project_managerReducer.loading,
  loading_project_code: state.project_managerReducer.loading,
  loading_company: state.companyReducer.loading,
  loading_export: state.milestoneReducer.loading_export,
  user_details: state.authReducer.user_details,
  options: {
    project_statuses: ProjectStatuses,
    project_code: _.toArray(
      _.mapValues(state.projectReducer.filteredProjects, o => {
        return {
          key: o.id,
          value: o.id,
          text: o.name + ' (' + o.code + ')',
          code: o.code,
          type: o.project_type,
          type_code: o.project_type_code,
          company_code: o.company_code,
          invoice_type_code: o.invoice_type_code,
        };
      })
    ).filter(s => (!(s.company_code === 'TESPA' && s.invoice_type_code === "SA") || (s.company_code === 'TEESP' && s.invoice_type_code === "SA"))),
    salesLevels: _.toArray(
      _.mapValues(state.salesLevelsReducer.levels, o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name,
          enabled: o.sl_enabled,
        };
      })
    ).filter(s => s.enabled === 1),
    currency: _.toArray(
      _.mapValues(state.currencyReducer.currencies, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.code,
        };
      })
    ),
  },
  loadings: {
    currency: state.currencyReducer.loading,
    project_code: state.projectReducer.loading,
  },
  billing_client_type: _.toArray(
    _.mapValues(state.customerReducer.customers, o => {
      return {
        key: o.id,
        value: o.name,
        text: o.name,
      };
    })
  ),
  milestone_status_type: _.toArray(
    _.mapValues(state.milestoneStatusReducer.status, o => {
      return {
        key: o.id,
        value: o.code,
        text: o.name,
      };
    })
  ),
  project_managers: _.toArray(
    _.mapValues(state.project_managerReducer.project_manager, o => {
      return {
        key: o.unique_id,
        value: o.name + ' ' + o.surname,
        text: o.name + ' ' + o.surname,
      };
    })
  ),
  project_manager: _.toArray(
    _.mapValues(state.project_managerReducer.project_manager, o => {
      return {
        key: o.unique_id,
        value: o.name + ' ' + o.surname,
        text: o.name + ' ' + o.surname,
      };
    })
  ),
  projects: _.toArray(
    _.mapValues(state.projectReducer.filteredProjects, o => {
      return {
        key: o.id,
        value: o.id,
        text: o.code,
        code: o.code,
        type: o.project_type,
      };
    })
  ),
  company: _.toArray(
    _.mapValues(state.companyReducer.companies, o => {
      return {
        key: o.id,
        value: o.id,
        text: o.name,
      };
    })
  ),
})

function mapDispatchToProps(dispatch) {
  return {
    getMilestoneList: params => dispatch(startGetMilestoneList(params)),
    getMilestone: id => dispatch(startGetMilestone(id)),
    getMilestoneListDownload: (params, onSuccess, onFailure) => dispatch(startDownloadMilestoneList(params, onSuccess, onFailure)),
    createMilestone: (params, onSuccess, onFailure) => dispatch(startCreateMilestone(params, onSuccess, onFailure)),
    approveMilestone: (milestoneId, note, onSuccess, onFailure) => dispatch(startApproveMilestone(milestoneId, note, onSuccess, onFailure)),
    rejectMilestone: (milestoneId, note, onSuccess, onFailure) => dispatch(startRejectMilestone(milestoneId, note, onSuccess, onFailure)),
    billMilestone: (milestoneId, onSuccess, onFailure) => dispatch(startSetBilledStatus(milestoneId, onSuccess, onFailure)),
    getProjects: (query, unique_id) => dispatch(startFilterProjects('invoice', query, unique_id, 0, "READ_INVOICES")),
    getProjectManagers: () => dispatch(startGetProject_manager("READ_INVOICES")),
    getCompanies: () => dispatch(startGetCompanies("EXPORT_INVOICES")),
    resetSearch: () => dispatch(resetSearch()),
    resetSearchManager: () => dispatch(resetSearchManager()),
    clearMilestones: () => dispatch(startClearMilestones()),
    deleteMilestone: (id, onSuccess, onFailure) => dispatch(startDeleteMilestone(id, onSuccess, onFailure)),
    getSalesLevels: projectId => dispatch(startGetSalesLevelsForProject(projectId)),
    getCurrencies: () => dispatch(startGetCurrencies()),

    //AM-002: "Delete Milestone" button - BEGIN
    //clearSalesLevels: () => dispatch(clearSalesLevels())
    clearSalesLevels: () => dispatch(clearSalesLevels()),
    unapproveMilestone: (milestoneId, note, onSuccess, onFailure) => dispatch(startUnapproveMilestone(milestoneId, note, onSuccess, onFailure))
    //AM-002: "Delete Milestone" button - END
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesContainer);
