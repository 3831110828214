import * as React from 'react';
import { Button, Container, Dimmer, Loader, Segment, Form, Modal, Radio, Search, Grid, Dropdown, Popup, Label } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import constants from '../../../utils/constants';
import { startApprovePFE, startConfirmPFE, startCrPFESheet, startGetPfes, startGetPfesById, startRejectPFE, startUpdatePFESheet, resetSearch, startUpdateOfferCode, notifyAdmin } from '../../../store/actions/core/project-planning/pfe'; //startGetPfesSearch
import { startFilterProjects } from '../../../store/actions/core/project/project';
import { startGetProjectTypes } from '../../../store/actions/core/administration/project_type'
import PaginatedReusableTable from '../../../components/shared/PaginatedReusableTable';
import ReusableHeaderWithHelp from '../../../components/shared/ReusableHeaderWithHelp';
import { history } from '../../../routers/AppRouters';
import PfeModal from '../../../components/core/project_planning/PfeModal';
import Can from '../../../abilities/Can';
import matchSorter from 'match-sorter';
import _, { isEmpty } from 'lodash';
import { amISuperAdmin } from '../../../utils/functionUtils'
//import IconButton from "../../../components/shared/buttons/IconButton";
import { capitalize } from '../../../utils/stringUtils';


// TEP-64: edit PFE search by project code and project name
const searchOptions = [
  { value: "project", label: "Project Code & Description" },
  // { value: "project_name", label: "Project description" },
  { value: "project_type", label: "Project Type" },
  { value: "billing_client", label: "Billing Client" },
  { value: "project_manager", label: "Project Manager" }
]

const ProjectStatuses = [
  { value: "all", text: "All" },
  { value: "active", text: "Active" },
  { value: "inactive", text: "Inactive" },
  { value: "pending", text: "Pending" },
  { value: "rejected", text: "Rejected" },
];

const PFECols = [
  {
    Header: 'Project Code',
    accessor: 'project_code',
    id: 'project_code',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Description',
    accessor: 'description',
    id: 'description',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Project Type',
    accessor: 'project_type',
    id: 'project_type',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Project Status',
    accessor: 'project_status',
    id: 'project_status',
    filterAll: true,
    Cell: row => capitalize(row.value),
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    id: 'start_date',
    filterAll: true,
    width: 88,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    id: 'end_date',
    filterAll: true,
    width: 88,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },

  // AEP-75: Creation date column in PFE/Budget page
  {
    Header: 'Creation Date',
    accessor: 'creation_date',
    id: 'creation_date',
    filterAll: true,
    width: 88,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },

  {
    Header: 'Billing Client',
    accessor: 'billing_client',
    id: 'billing_client',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Project Manager',
    accessor: 'project_manager',
    id: 'project_manager',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    width: 65,
    Cell: row => {
      switch (row.value) {
        case "created":
          return "Created";
        case "approved":
          return "Approved";
        case "rejected":
          return "Rejected";
        case "change requested":
          return "Change Requested";
        case "confirmed":
          return "Confirmed";
        default:
          return "";
      }
    },
    filterMethod: (filter, row) => {
      if (filter.value === 'all') {
        return true;
      } else {
        //console.log("FILTER:", row.status, filter.value);
        return row.status === filter.value;
      }
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Show All</option>
        <option value="created">Created</option>
        <option value="approved">Approved</option>
        <option value="rejected">Rejected</option>
        <option value="change requested">Change Requested</option>
        <option value="confirmed">Confirmed</option>
      </select>
    )
  },
  {
    Header: 'Currency',
    accessor: 'currency_code',
    id: 'currency_code',
    filterAll: true,
    width: 61,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Gross Margin',
    accessor: 'gross_margin',
    id: 'gross_margin',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  /*{
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    Filter: this.selectColumnFilter,
    filter: 'includes'
  },*/

];

class PfeNextContainer extends React.Component {
  constructor(props) {
    super(props);
    var filters = {};
    filters["start"] = 0;
    filters["size"] = 20;
    filters["order_direction"] = "desc";
    filters["order"] = "creation_date";
    filters["projectStatus"] = "all";

    this.state = {
      showFormModal: false,
      //search filters
      filters: filters,
      openSearchModal: false,
      searchValue: "",
      searchOption: "project",
      modalMail: false,
      //PaginatedTable state
      page: 0,
      pageSize: 20,
      selectedRow: {},
      user_groups: [],
      //note: ''
      warningPFEApproval: false,
    };
  }

  componentWillMount() {
    this.props.getProjectTypes();
    this.props.getPfes(this.state.searchOption, "", this.state.filters);
  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    let user_groups = {};
    if (this.props.user)
      if (this.props.user.signInUserSession)
        if (this.props.user.signInUserSession.idToken)
          if (this.props.user.signInUserSession.idToken.payload) {
            user_groups = JSON.parse(this.props.user.signInUserSession.idToken.payload.user_groups);
          }

    this.setState({
      user_groups: user_groups
    });
  }

  componentDidUpdate() {
    let user_groups = {};
    if (user_groups === null) {
      user_groups = JSON.parse(this.props.user.signInUserSession.idToken.payload.user_groups);

      this.setState(prevState => ({
        ...prevState,
        user_groups: user_groups
      }));
    }
  }

  onPageChange = pageIndex => {
    //console.log("PAGE INDEX", pageIndex);
    this.setState({ page: pageIndex });
    // TODO fetch new data if necessary
    let filters = this.state.filters;
    filters['start'] = pageIndex * this.state.pageSize;
    filters['size'] = this.state.pageSize;

    //console.log(filters);
    this.props.getPfes(this.state.searchOption, this.state.searchValue, filters, false); // fetch filtered reports
  };

  onSelectRow = selectedRow => {
    this.props.getPfesById(selectedRow.id);
    this.setState(prevState => ({
      ...prevState,
      selectedRow: selectedRow,
      showFormModal: true,
    }));
  };

  handleSubmitEndDate = (end_date) => {
    //console.log("YESSSS ", end_date);
    const onFailure = res => {
      //console.log('onError', res);
      let description = "";
      if (res.response && res.response.status === 406) {
        description = res.response.data.error;
      } else {
        description = "The PFE cannot be changed. Try again";
      }
      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
      this.closeModal();
    };

    const onSuccess = res => {
      //console.log('onSuccess', res);
      toast({
        title: 'Success',
        description: 'The PFE has been changed.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      //this.props.getPfesById(res.pfe_id);
      this.setState({
        modalMail: true,
        changeRequest: true,
        confirm: false,
      });
    };

    this.props.onCrPFE(this.state.selectedRow.id, { end_date }, onSuccess, onFailure);
  }

  handleConfirm = () => {
    const onFailure = res => {
      //console.log('onError', res);
      toast({
        title: 'Error',
        description: res.response.data.error,//res.toString(),
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
      this.closeModal();
    };

    const onSuccess = res => {
      //console.log('onSuccess', res);
      toast({
        title: 'Success',
        description: 'The PFE has been confirmed.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      this.closeModal();

      this.setState({
        modalMail: true,
        changeRequest: false,
        confirm: true
      });
    };

    //console.log('PFE confirmation', this.state.selectedRow);
    this.props.onConfirmPFE(
      this.state.selectedRow.id,
      this.state.filters,
      onSuccess,
      onFailure
    );
  };

  handleApproval = () => {
    if (!this.state.warningPFEApproval) {
      if (amISuperAdmin(this.props.user_groups)) {
        if (window.confirm("Warning: PFE approval should be performed by the approver! Do you want to continue?")) {
          this.setState((prevState) => ({
            ...prevState,
            warningPFEApproval: true
          }))
        }
        else {
          return;
        }
      }
    }

    const onFailure = res => {
      //console.log('onError', res);
      let description = "";
      if (res.response && res.response.status === 406) {
        description = res.response.data.error;
      } else {
        description = "The PFE hasn't been approved. Try again.";
      }
      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
      this.closeModal();
    };

    const onSuccess = res => {
      //console.log('onSuccess', res);
      toast({
        title: 'Success',
        description: 'The PFE has been approved.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      this.closeModal();
    };

    //console.log('PFE approval', this.state.selectedRow);
    this.props.onApprovalPFE(
      this.state.selectedRow.id,
      this.state.filters,
      onSuccess,
      onFailure
    );
  };

  handleReject = () => {
    const onFailure = res => {
      //console.log('onError', res);
      let description = "";
      if (res.response && res.response.status === 406) {
        description = res.response.data.error;
      } else {
        description = "The PFE hasn't been rejected. Try again.";
      }
      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
      this.closeModal();
    };

    const onSuccess = res => {
      //console.log('onSuccess', res);
      toast({
        title: 'Success',
        description: 'The PFE has been rejected.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      this.closeModal();
    };

    //console.log('PFE reject', this.state.selectedRow);
    this.props.onRejectPFE(
      this.state.selectedRow.id,
      //this.state.note,
      this.state.filters,
      onSuccess,
      onFailure
    );
  };

  handleUpdateSheet = () => {
    const onFailure = res => {
      //console.log('onError', res);
      toast({
        title: 'Error',
        description: "The PFE sheet hasn't been updated. Try again.",
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      //console.log('onSuccess', res);
      toast({
        title: 'Success',
        description: 'The PFE sheet has been updated.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
    };

    //console.log('PFE sheet update', this.state.selectedRow);
    this.props.onUpdateSheet(this.state.selectedRow.id, onSuccess, onFailure);
  };

  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      showFormModal: false,
      selectedRow: {},
      modalMail: false
      // showDeleteButtons: false,
      // note: ''
    }));

    this.props.getPfes(this.state.searchOption, this.state.searchValue, this.state.filters);
  };

  onCloseSearchModal = () => {
    this.setState(prevState => ({
      ...prevState,
      openSearchModal: false
    }));
  }

  handleChangeRadio = (e, { value }) => {
    this.setState(prevState => ({
      ...prevState,
      searchOption: value,
      searchValue: ""
    }));
  }

  handleSearchChange = (event, data) => {
    //console.log(data.value);

    this.setState(prevState => ({
      ...prevState,
      searchValue: data.value,
    }));

    if (data.value.length >= 2) {
      //if (this.state.searchValue.length >= 2) {
      //console.log("[startGetPfesSearch]", data.value, this.state.filters);
      const debouncedSearch = _.debounce((query) => {
        this.props.getPfes(this.state.searchOption, data.value, this.state.filters);
      }, 1000)

      debouncedSearch();
    } else {
      //console.log("LENGTH: ", this.state.searchValue.length);
      //this.props.resetSearch();
    }
  }

  onSelectChange = (e, data) => {
    //console.log(data.name, data.value);

    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [data.name]: data.value
      }
    }));

    let filters = this.state.filters;
    filters[data.name] = data.value;

    if (data.value.length >= 2) {
      //if (this.state.searchValue.length >= 2) {
      this.props.getPfes(this.state.searchOption, this.state.searchValue, filters);
    }
  };

  handleSearchDropdown = (event, data) => {
    //console.log(data);
    var project_code = data.options.find(o => o.value === data.value).code;
    this.setState(prevState => ({
      ...prevState,
      searchValue: project_code
    }));
    //console.log(this.state.filters);
    console.log(this.state.searchOption, project_code, this.state.filters)
    this.props.getPfes(this.state.searchOption, project_code, this.state.filters);
  }

  // showDeleteButtons = () => {
  //   this.setState({ showDeleteButtons: true });
  // };

  // handleChange = (e, data) => {
  //   console.log(data.name, data.value);

  //   this.setState(prevState => (
  //     {
  //       ...prevState,
  //       [data.name]: data.value
  //     }
  //   ));
  // }

  render() {

    //console.log(this.state.filters);
    let data = this.props.pfes ? this.props.pfes.data : [{}];
    const meta = this.props.pfes ? this.props.pfes.meta : undefined;

    let pages = -1;
    if (meta) {
      pages = Math.ceil(meta.total_element / meta.size);
    }
    //console.log(data, pages, this.state.page, this.state.pageSize, this.props.loadingPfes);

    return (
      <div>
        <Dimmer active={this.props.loadingPfes} inverted>
          <Loader indeterminate inverted content="Loading" size="medium" />
        </Dimmer>
        <Container>
          <ReusableHeaderWithHelp title="PFE" link="/help/project-planning/pfe" />

          <Can I="project_planning:Read" a="pfes">
            {this.state.showFormModal && !this.props.loadingPfe ? (
              <PfeModal
                values={this.props.pfe}
                getProjects={this.props.getAllProjects}
                all_projects={this.props.all_projects}
                loadingProjectSelect={this.props.loadingProjectSelect}
                updateOfferCode={this.props.updateOfferCode}
                notifyAdmin={this.props.notifyAdmin}
                onClose={this.closeModal}
                onConfirm={this.handleConfirm}
                onApprove={this.handleApproval}
                onReject={this.handleReject}
                onUpdateSheets={this.handleUpdateSheet}
                creation={false}
                onSubmitEndDate={this.handleSubmitEndDate}
                loading={this.props.loadingPfes}
                loadingCR={this.props.loadingPfeCreation}
              // showDeleteButtons={this.showDeleteButtons}
              />
            ) : (
              <Dimmer active={this.props.loadingPfe} inverted>
                <Loader
                  indeterminate
                  inverted
                  content="Loading"
                  size="medium"
                />
              </Dimmer>
            )}
            {
              <Segment clearing>
                <Grid columns={3} stackable>

                  <Grid.Row>
                    <Grid.Column className="search_budget">
                      <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Search PFE by {(searchOptions.filter(x => x.value == this.state.searchOption))[0].label}</Label>
                      <Popup
                        content={"Type " + (searchOptions.filter(x => x.value == this.state.searchOption))[0].label + " digits to start searching"}
                        trigger={(
                          <Label color="teal" circular>
                            ?
                          </Label>
                        )}
                      />
                      {this.state.searchOption !== "project_type" ?
                        <Search
                          name="search"
                          input={{ fluid: true }}
                          placeholder={"Search all PFEs by " + (searchOptions.filter(x => x.value == this.state.searchOption))[0].label + "..."}
                          showNoResults={false}
                          minCharacters={3}
                          loading={this.props.loadingPfes}
                          onSearchChange={this.handleSearchChange}
                          size="large"
                          value={this.state.searchValue}
                        />
                        :
                        <Dropdown
                          name="dropdown_type"
                          placeholder='Choose a Project Type'
                          fluid
                          selection
                          options={this.props.options.project_type}
                          onChange={this.handleSearchDropdown}
                        />}
                      <Button
                        floated="left"
                        basic
                        style={{ 'margin': '5px 10px 0px 10px' }}
                        content={"Search Option: " + ((searchOptions.filter(x => x.value == this.state.searchOption))[0].label).toUpperCase()}
                        onClick={() => this.setState({ openSearchModal: true })}
                        color='teal'
                        icon='filter'
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input type="hidden" />
                      <label>Project Status</label>
                      <Form.Select
                        placeholder="Filter by..."
                        name="projectStatus"
                        options={this.props.options.projectStatuses}
                        onChange={this.onSelectChange}
                        value={this.state.filters.projectStatus}
                      />
                    </Grid.Column>
                    <Grid.Column >

                      <Popup content="Refresh page with the same search parameter" trigger={<Button
                        content="Refresh" floated='right' icon='refresh'
                        onClick={() => this.props.getPfes(this.state.searchOption, this.state.searchValue, this.state.filters)} />} />

                      <Can I="project_planning:Create" a="pfes">
                        <Popup content="Create a new PFE for your project" trigger={<Button
                          content="New PFE" floated='right' icon='check'
                          onClick={() =>
                            history.push({
                              pathname: '/project-planning/PFE/new',
                            })
                          } />} />
                      </Can>

                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <div style={{ marginTop: '50px' }}>
                  <PaginatedReusableTable
                    manual={pages > 1 ? true : false}
                    filterable={pages > 1 ? false : true}
                    sortable={pages > 1 ? false : true}
                    loading={this.props.loadingPfes}
                    columns={PFECols}
                    data={data}
                    onClick={this.onSelectRow}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pages={pages}
                    pageSize={this.state.pageSize}
                  />
                </div>
              </Segment>
            }
          </Can>

          <Modal
            size="mini"
            open={this.state.openSearchModal}
            closeOnEscape={false}
            closeOnDimmerClick={true}
            onClose={this.onCloseSearchModal}
            closeIcon
            dimmer="blurring"
          >

            <Modal.Header>Select Search Option</Modal.Header>
            <Modal.Content>
              <Form size="massive">
                {searchOptions.map((field, i) => {
                  if (field.value === 'project_manager' && !amISuperAdmin(this.props.user_groups)) {
                    return null;
                  }
                  return (
                    <Form.Field key={i}>
                      <Radio
                        label={field.label}
                        value={field.value}
                        name='radioGroup'
                        checked={this.state.searchOption === field.value}
                        onChange={this.handleChangeRadio}
                      />
                    </Form.Field>
                  );
                })}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.onCloseSearchModal} content='Select' />
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.modalMail}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            dimmer='inverted'
            size='tiny'
          >
            <Modal.Header>Confirm change status of PFE {this.props.pfe ? this.props.pfe.project_code : ""}</Modal.Header>
            <Modal.Content>
              <p>The PFE has been {this.state.confirm ? "confirmed" : "modified"}.</p>
              <p>You will receive an email as soon as the spreadsheet is available.</p>
            </Modal.Content>
            <Modal.Actions>
              <Button className="cancel" floated='left' onClick={this.closeModal}>Close</Button>
            </Modal.Actions>
          </Modal>

          {/* <Modal
            size="small"
            dimmer="inverted"
            open={this.state.showDeleteButtons}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            onClose={this.closeModal}
            closeIcon>

            <Dimmer active={this.props.loadingPfes} inverted>
              <Loader
                indeterminate
                inverted
                content="Loading"
                size="medium"
              />
            </Dimmer>
            <Modal.Header>
              {'PFE - ' + this.state.selectedRow.project_code + ' - Reject'}
            </Modal.Header>

            <Modal.Content>
              <Form>
                <Form.Field
                  control={TextArea}
                  name="note"
                  label="Rejection reasons"
                  required
                  onChange={this.handleChange}
                />

              </Form>
            </Modal.Content>

            <Modal.Actions>

              <IconButton
                floated="right"
                icon="check"
                label="YES"
                disabled={this.state.note === ''}
                onClick={this.handleReject}
              />

              <Button
                floated="left"
                negative
                onClick={this.closeModal}>
                No
              </Button>
            </Modal.Actions>
          </Modal> */}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  user_groups: state.authReducer.user_groups,
  loadingPfes: state.pfeReducer.loading,
  loadingPfe: state.pfeReducer.loading_pfe,
  loadingPfeCreation: state.pfeReducer.loadingPfeCreation,
  pfes: state.pfeReducer.pfes,
  pfe: state.pfeReducer.pfe,
  loadingProjectSelect: state.projectReducer.loading,

  all_projects: _.toArray(
    _.mapValues(state.projectReducer.filteredAllProjects, o => {
      return {
        key: o.id,
        value: o.code,
        text: o.code
      }
    })
  ),

  options: {
    projectStatuses: ProjectStatuses,
    project_type: _.toArray(
      _.mapValues(_.filter(state.projectTypeReducer.projecttypes, { 'active': 1, 'corporate': 0 }), o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name,
          code: o.code
        };
      })
    ),
  }

});

const mapDispatchToProps = dispatch => {
  return {
    getPfes: (searchOption, searchValue, params) => dispatch(startGetPfes(searchOption, searchValue, params, false)),
    //getPfesSearch: (searchOption, searchValue, params) => dispatch(startGetPfesSearch(searchOption, searchValue, params)),
    getPfesById: id => dispatch(startGetPfesById(id)),
    onConfirmPFE: (id, params, onSuccess, onFailure) =>
      dispatch(startConfirmPFE(id, params, onSuccess, onFailure)),
    onApprovalPFE: (id, params, onSuccess, onFailure) =>
      dispatch(startApprovePFE(id, params, onSuccess, onFailure)),
    // onRejectPFE: (id, note, params, onSuccess, onFailure) =>
    //   dispatch(startRejectPFE(id, note, params, onSuccess, onFailure)),
    onRejectPFE: (id, params, onSuccess, onFailure) =>
      dispatch(startRejectPFE(id, params, onSuccess, onFailure)),
    onUpdateSheet: (id, onSuccess, onFailure) =>
      dispatch(startUpdatePFESheet(id, onSuccess, onFailure)),
    onCrPFE: (id, params, onSuccess, onFailure) =>
      dispatch(startCrPFESheet(id, params, onSuccess, onFailure)),
    getProjectTypes: () => dispatch(startGetProjectTypes()),
    resetSearch: () => dispatch(resetSearch()),
    getAllProjects: (query, unique_id) => dispatch(startFilterProjects('offer_request', query, unique_id)),
    updateOfferCode: (pfe_id, offer_code_id, onSuccess, onFailure) =>
      dispatch(startUpdateOfferCode(pfe_id, offer_code_id, onSuccess, onFailure)),
    notifyAdmin: (pfe_id, onSuccess, onFailure) => dispatch(notifyAdmin(pfe_id, onSuccess, onFailure))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PfeNextContainer);
