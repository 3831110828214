import React, { Component } from "react"
import { Checkbox, Form, Label } from "semantic-ui-react"

class ProjectForm extends Component {
  state = {
    required: [],
    showDeleteButtons: false,
  }

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false })
  }

  getRequiredFields = (fields) => {
    const required = []

    fields.forEach((field) => {
      if (Array.isArray(field)) {
        field.forEach((groupField) => {
          if (groupField.required) {
            required.push(groupField.name)
          }
        })
      } else {
        if (field.required) {
          required.push(field.name)
        }
      }
    })
    return required
  }

  componentDidMount() {
    const { formFields } = this.props
    this.setState({ required: this.getRequiredFields(formFields) })
  }

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true })
  }

  render() {
    const { values, loadings, options, formFields, editable, errorNoMoreBudget, showUseExpensesApprover } = this.props // AEP-137: showUseExpensesApprover per visualizzare o meno il campo approver

    console.log('values', values);

    return (
      <React.Fragment>
        <Form style={{ clear: "both" }}>
          {!editable ? (
            <div>
              {errorNoMoreBudget && (
                <div style={{ marginBottom: "2em" }}>
                  <Label color="red" ribbon>
                    no budget available for the current month
                  </Label>
                </div>
              )}

              {formFields.map((field, i) => {
                if (Array.isArray(field)) {
                  return (
                    <Form.Group widths="equal" key={i}>
                      {field.map((groupField) => {
                        const fullNameField = fullNameFields.find((field) => field.labelKey === groupField.name)
                        if (fullNameField) {
                          return (
                            <div className="custom-form-field" key={groupField.label}>
                              <label>{groupField.label}</label>
                              <div>{`${values[fullNameField.surnameKey]} ${values[fullNameField.nameKey]}`}</div>
                            </div>
                          )
                        }
                        if (
                          groupField.name === "delta_revenues_closure" &&
                          values[groupField.name] !== "" &&
                          "extra_function" in groupField
                        ) {
                          return (
                            <div className="custom-form-field" key={groupField.label}>
                              <label>{groupField.label}</label>
                              <div>
                                <a onClick={this.props[groupField.extra_function]} style={{ cursor: "pointer" }}>
                                  {values[groupField.name]}
                                </a>
                              </div>
                            </div>
                          )
                        } else if (values[groupField.name] !== "") {
                          return (
                            // there are two fields with the same name so the key must be the label
                            <div className="custom-form-field" key={groupField.label}>
                              {console.log('groupField', groupField)}
                              {// AEP-137: condizione per mostrare o meno l'approver
                                (groupField.name === "approver_name" || groupField.name === "approver_surname") &&
                                  !showUseExpensesApprover ? null : (
                                  <>
                                    <label>{groupField.label}</label>
                                    {groupField.control === "Link" && values[groupField.name] ?
                                      (
                                        <div>
                                          <a href={values[groupField.name]} target={groupField.target}>
                                            {groupField.link_label}
                                          </a>{" "}
                                        </div>
                                      ) : groupField.control === "Link" ? (
                                        <div>
                                          <a
                                            href={
                                              `/project-planning/${groupField.link_label}${(values.pfe_link || values.budget_link) ? '/' : '_next/'}${groupField.link_label === 'PFE'
                                                ? (values.pfe_id ? values.pfe_id : (values.pfe_link ? values.pfe_link : ''))
                                                : (values.budget_id ? values.budget_id : (values.budget_link ? values.budget_link : ''))}`
                                            }
                                            target={groupField.target}>
                                            {groupField.link_label}
                                          </a>{" "}
                                        </div>
                                      ) : groupField.control !== "Checkbox" ? (
                                        // AEP-2467: nella sezione Work Experience il campo 'Visible On CV' visualizza Yes o No invece di 1 o 0.
                                        <div>{values[groupField.name] === 1 ? 'Yes' : values[groupField.name] === 0 ? 'No' : values[groupField.name]}</div>
                                      ) : values[groupField.name] === 0 ? (
                                        <div>No</div>
                                      ) : (
                                        <div>Yes</div>
                                      )}
                                  </>
                                )}
                            </div>
                          )
                        } else {
                          return ""
                        }
                      })}
                    </Form.Group>
                  )
                } else {
                  if (values[field.name] !== "" && values[field.name] !== 1) {
                    return (
                      <div className="custom-form-field" key={field.name}>
                        <label>{field.label}</label>
                        {field.control === "Link" ? (
                          <div>
                            <a href={values[field.name]} target={field.target}>
                              {field.link_label}
                            </a>{" "}
                          </div>
                        ) : field.control !== Checkbox ? (
                          <div>{values[field.name]}</div>
                        ) : values[field.name] === 0 ? (
                          <div>No</div>
                        ) : (
                          <div>Yes</div>
                        )}
                      </div>
                    )
                  } else if (values[field.name] === 1 && field.name === "daily_rate") {
                    return (
                      <div className="custom-form-field delete" key={field.name}>
                        <label>{field.label}</label>
                        {field.control !== Checkbox ? (
                          <div>{values[field.name]}</div>
                        ) : values[field.name] === 0 ? (
                          <div>No</div>
                        ) : (
                          <div>Yes</div>
                        )}
                      </div>
                    )
                  } else {
                    return ""
                  }
                }
              })}
            </div>
          ) : (
            <div>
              {formFields.map((field, i) => {
                if (Array.isArray(field)) {
                  return (
                    <Form.Group widths="equal" key={i}>
                      {field.map((groupField, ii) => {
                        if (groupField.control === Checkbox) {
                          groupField.checked = values[groupField.name]
                          groupField.readOnly = false
                        } else {
                          groupField.value = values[groupField.name]
                        }
                        return (
                          <Form.Field
                            key={ii}
                            {...groupField}
                            search={"search" in field && field.search}
                            options={options[groupField.name]}
                            loading={loadings[groupField.name]}
                            onChange={this.props.handleChange}
                          />
                        )
                      })}
                    </Form.Group>
                  )
                } else {
                  if (field.control === Checkbox) {
                    field.checked = values[field.name]
                  } else {
                    field.value = values[field.name]
                  }

                  return (
                    // AEP-137: condizione per mostrare o meno l'approver
                    field.name === "approver" && !showUseExpensesApprover ? null : (
                      <>
                        <Form.Field
                          key={i}
                          {...field}
                          options={options[field.name]}
                          loading={loadings[field.name]}
                          onChange={this.props.handleChange}
                          search={"search" in field && field.search}
                          disabled={editable && field.name === "type"}
                        />
                      </>
                    )
                  )
                }
              })}
            </div>
          )}
        </Form>
      </React.Fragment>
    )
  }
}

export default ProjectForm

const fullNameFields = [
  { surnameKey: "manager_surname", nameKey: "manager_name", labelKey: "manager_fullName" },
  { surnameKey: "approver_surname", nameKey: "approver_name", labelKey: "approver_fullName" },
  { surnameKey: "customer_manager_surname", nameKey: "customer_manager_name", labelKey: "customerManager_fullName" },
]
