
export const sleep = (s) => {
    return new Promise(resolve => setTimeout(resolve, (s*1000)))
  }

export const formatNumber = (number) => {
  let formatter = new Intl.NumberFormat('it-IT', {
    notation: 'standard',
    style: 'decimal'
  });

  let value = number;

  if (number){
    value = formatter.format(value);
  }
  return value;
}

export const amISuperAdmin = (userGroups) => {
  // return (userGroups && userGroups.length > 0 && userGroups.find(u => u === 'ia-superadmin') === "ia-superadmin")
  return isUserInGroup(userGroups, 'ia-superadmin')
}

export const isUserInGroup = (userGroups, group) => {
  return (userGroups && userGroups.length > 0 && userGroups.find(u => u === group) === group)
}

//AM-002: "Delete Milestone" button - BEGIN
export const amIFullAdmin = (userGroups) => {
  return isUserInGroup(userGroups, 'ia-fulladministrator') || isUserInGroup(userGroups, 'ia-fulladministrator-admin')
}
//AM-002: "Delete Milestone" button - END 

export const getTitleDecorator = (href) => {
  var decoration = ''
  if (href.split('/')[2].split('.')[0]==='portal-qas'){
    decoration = ' - QUALITY'
  }
  else if (href.split('/')[2].split('.')[0]==='portal-dev'){
    decoration = ' - DEVELOPMENT'
  }
  else if ((href.split('/')[2].split('.')[0]).includes('localhost')){
    decoration = ' - LOCAL'
  }
  else if ((href.split('/')[2].split('.')[0]).includes('uat')){
    decoration = ' - UAT'
  }
  return decoration
}