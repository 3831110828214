import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Container, Header, Icon, Segment, Grid, Divider, Tab, Search, Dimmer, Loader, Modal } from "semantic-ui-react";
import DatePicker from 'react-datepicker/es';
import 'react-datepicker/dist/react-datepicker.css';
import Can from "../../../abilities/Can";
import matchSorter from "match-sorter";
import ReusableTable from "../../../components/shared/ReusableTable";
import IconButton from "../../../components/shared/buttons/IconButton";
import moment from "moment";
import { Form } from 'formsy-semantic-ui-react';
import _ from "lodash";
import { toast } from "react-semantic-toasts";
import constants from "../../../utils/constants";
import { monthStr } from "../../../utils/formUtils";
import { Storage } from "aws-amplify";
import {
  resetProjectSearch,
  startGetApprovalProjectList,
  startGetApprovalProject,
  startGetApprovalHourList,
  startApproveHours,
  startGetApprovalExpenseList,
  startApproveExpenses,
  resetProject,
  startExportData
} from "../../../store/actions/core/approval/project";
import { ApprovalModal } from "../../../components/core/approval/ApprovalModal";
import { ProjectHeader } from "../../../components/core/approval/ProjectHeader";
import { ProjectSummaryComponent } from "../../../components/core/approval/ProjectSummaryComponent";
import ApprovalTable from "../../../components/core/approval/ApprovalTable";
import { PDFDocument } from 'pdf-lib';
import { history } from "../../../routers/AppRouters";

class ApprovalProjectContainer extends Component {

  constructor(props) {
    super(props);

    var currentDate = moment(currentDate).subtract(1, 'months')

    let workingDays = this.getBusinessDatesCount(currentDate);


    this.state = {
      loading: this.props.loading,
      loadingHours: this.props.loadingHours,
      loadingExpenses: this.props.loadingExpenses,
      loadingExportData: this.props.loadingExportData,
      loadingProjectSummary: false,
      cn: '',
      user_id: '',
      user_details: [],
      user_groups: [],
      company_code: '',
      projects: null,
      selectedProject: null,
      activePeriod: {
        month: currentDate.month() + 1,
        year: currentDate.year(),
        workingDays: workingDays
      },
      selectedDate: currentDate.toDate(),
      selectedHours: [],
      filteredHours: [],
      selectedAllHours: false,
      hoursApprovalStatus: 'not_required',
      modalApproveHoursActive: false,
      modalRejectHoursActive: false,
      modalResetHoursActive: false,
      hoursRejectReason: '',

      selectedExpenses: [],
      filteredExpenses: [],
      selectedAllExpenses: false,
      expensesApprovalStatus: 'not_required',
      modalApproveExpensesActive: false,
      modalRejectExpensesActive: false,
      modalResetExpensesActive: false,
      expensesRejectReason: '',

      activeTabIndex: 0,
      hoursPerDay: 8,

      hours_approval_enabled_companies: process.env.REACT_APP_HOURS_APPROVAL_ENABLED_COMPANIES.split(","),
      expenses_approval_enabled_companies: process.env.REACT_APP_EXPENSES_APPROVAL_ENABLED_COMPANIES.split(","),

      searchProjectValue: "",

      singleProject: {},
    }

    this.handleOnApprovalHours = this.handleOnApprovalHours.bind(this);
    this.handleOnRejectHours = this.handleOnRejectHours.bind(this);
    this.handleOnResetHours = this.handleOnResetHours.bind(this);
    this.handleOnExportHours = this.handleOnExportHours.bind(this);
    this.onModalApproveHoursClose = this.onModalApproveHoursClose.bind(this);
    this.onConfirmApproveHours = this.onConfirmApproveHours.bind(this);
    this.onModalRejectHoursClose = this.onModalRejectHoursClose.bind(this);
    this.onConfirmRejectHours = this.onConfirmRejectHours.bind(this);
    this.onModalResetHoursClose = this.onModalResetHoursClose.bind(this);
    this.onConfirmResetHours = this.onConfirmResetHours.bind(this);
    this.handleRejectHoursReasonChange = this.handleRejectHoursReasonChange.bind(this);

    this.handleOnApprovalExpenses = this.handleOnApprovalExpenses.bind(this);
    this.handleOnRejectExpenses = this.handleOnRejectExpenses.bind(this);
    this.handleOnResetExpenses = this.handleOnResetExpenses.bind(this);
    this.handleOnExportExpenses = this.handleOnExportExpenses.bind(this);
    this.handleOnExportExpenseImages = this.handleOnExportExpenseImages.bind(this);
    this.onModalApproveExpensesClose = this.onModalApproveExpensesClose.bind(this);
    this.onConfirmApproveExpenses = this.onConfirmApproveExpenses.bind(this);
    this.onModalRejectExpensesClose = this.onModalRejectExpensesClose.bind(this);
    this.onConfirmRejectExpenses = this.onConfirmRejectExpenses.bind(this);
    this.onModalResetExpensesClose = this.onModalResetExpensesClose.bind(this);
    this.onConfirmResetExpenses = this.onConfirmResetExpenses.bind(this);
    this.handleRejectExpensesReasonChange = this.handleRejectExpensesReasonChange.bind(this);

    this.handleOnExportSummary = this.handleOnExportSummary.bind(this);

    this.onHourFiltersUpdated = this.onHourFiltersUpdated.bind(this);
    this.onExpenseFiltersUpdated = this.onExpenseFiltersUpdated.bind(this);

    if (this.props.user_id !== undefined) {
      if (!this.props.isSuperAdmin || this.state.searchProjectValue.length >= 3) {
        this.props.getApprovalProjectList(this.state.activePeriod.month, this.state.activePeriod.year, this.props.user_id, this.state.searchProjectValue);
      }
    }
  }



  // AEP-2113
  state = { isNotesModalOpen: false, selectedNote: '' };
  openNotesModal = (note) => this.setState({ isNotesModalOpen: true, selectedNote: note });
  closeNotesModal = () => this.setState({ isNotesModalOpen: false, selectedNote: '' });
  // AEP-2113


  getBusinessDatesCount(date) {

    var month = moment(date).month() + 1,
      year = moment(date).year();

    var firstDayOfMonth = moment([year, month - 1]),
      lastDayOfMonth = moment(firstDayOfMonth).endOf('month');

    var startDate = firstDayOfMonth.toDate(),
      endDate = lastDayOfMonth.toDate();

    var count = 0;
    var curDate = startDate;
    while (curDate <= endDate) {
      var dayOfWeek = curDate.getDay();
      if (!((dayOfWeek === 6) || (dayOfWeek === 0)))
        count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

  handleTabChange = (e, { activeTabIndex }) => {
    this.setState(prevState => ({
      ...prevState,
      activeTabIndex: activeTabIndex
    }));
  };

  handleActivePeriodChange = (e, data) => {
    let activePeriod = {};
    if (data.options) {
      activePeriod = {
        ...this.state.activePeriod,
        month: data.value
      };
    } else {
      activePeriod = {
        ...this.state.activePeriod,
        year: data.value
      };
    }
    this.setState({
      activePeriod: activePeriod
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cn !== prevProps.cn ||
      this.state.activePeriod.month !== prevState.activePeriod.month ||
      this.state.activePeriod.year !== prevState.activePeriod.year) {
      if (!this.props.isSuperAdmin || this.state.searchProjectValue.length >= 3) {
        this.props.getApprovalProjectList(this.state.activePeriod.month, this.state.activePeriod.year, this.props.user_id, this.state.searchProjectValue);
      }
    }
    if (this.state.selectedProject !== prevState.selectedProject) {
      if (this.state.selectedProject != null) {
        this.props.getApprovalProject(this.state.activePeriod.month, this.state.activePeriod.year, this.props.user_id, this.state.selectedProject.project_id);
        this.props.getApprovalHourList(this.state.activePeriod.month, this.state.activePeriod.year, this.props.user_id, this.state.selectedProject.project_id);
        this.props.getApprovalExpenseList(this.state.activePeriod.month, this.state.activePeriod.year, this.props.user_id, this.state.selectedProject.project_id);
      }
    }
    if (this.props.hours !== prevProps.hours) {
      let hoursApprovalStatus = 'not_required';
      if (this.props.hours.length > 0) {
        hoursApprovalStatus = 'complete';
        for (var i = this.props.hours.length - 1; i > -1; i--) {
          if (this.props.hours[i].status !== 'approved' && this.props.hours[i].status !== 'rejected') {
            hoursApprovalStatus = 'incomplete';
            break;
          }
        }
      }
      this.setState({
        hoursApprovalStatus: hoursApprovalStatus,
      });

      let filteredHours = _.toArray(
        _.mapValues(this.props.hours, h => {

          return {
            _original: {
              id: h.id,
              employee: h.employee,
              role: h.role,
              date: h.date,
              hour_type: h.hour_type,
              billable_hours: h.billable_hours,
              non_billable_hours: h.non_billable_hours,
              status: h.status,
              notes: h.notes
            }
          }
        })
      )

      this.setState(prevState => {
        return {
          ...prevState,
          filteredHours: filteredHours
        }
      })
    }
    if (this.props.expenses !== prevProps.expenses) {
      let expensesApprovalStatus = 'not_required';
      if (this.props.expenses.length > 0) {
        expensesApprovalStatus = 'complete';
        for (let i = this.props.expenses.length - 1; i > -1; i--) {
          if (this.props.expenses[i].status !== 'OK' && this.props.expenses[i].status !== 'CLOSED' && this.props.expenses[i].status !== 'KO_PM' && this.props.expenses[i].status !== 'KO_HR') {
            expensesApprovalStatus = 'incomplete';
            break;
          }
        }
      }

      let filteredExpenses = _.toArray(
        _.mapValues(this.props.expenses, e => {

          return {
            _original: {
              id: e.id,
              employee: e.employee,
              role: e.role,
              date: e.date,
              expense_type: e.expense_type,
              amount: e.amount,
              currency: e.currency,
              billable: e.billable,
              receipt: e.receipt,
              notes: e.notes,
              status: e.status,
              status_name: e.status_name,
              need_receipt: e.need_receipt,
              conversion_rate: e.conversion_rate
            }
          };
        })
      )

      this.setState({
        expensesApprovalStatus: expensesApprovalStatus,
        filteredExpenses: filteredExpenses
      });
    }
  }

  handleRowClick = clickedRow => {
    var selectedProjectId = clickedRow.project_id;

    let selectedProject = this.props.projects.find((element) => {
      return element.project_id === selectedProjectId;
    });

    this.setState({
      selectedProject: selectedProject
    });
  };

  clearProjectSelected() {
    this.setState({
      selectedProject: null,
    });
    this.props.resetProject();
    this.props.getApprovalProjectList(this.state.activePeriod.month, this.state.activePeriod.year, this.props.user_id, this.state.searchProjectValue);
  }

  setStartDate(date) {

    var month = moment(date).month() + 1,
      year = moment(date).year();

    let workingDays = this.getBusinessDatesCount(date);

    this.setState({
      selectedDate: date,
      activePeriod: {
        month: month,
        year: year,
        workingDays: workingDays
      },
    });
  }

  toggleHoursRow(hour_id) {
    let selectedHours = this.state.selectedHours.slice(0);
    var index = selectedHours.indexOf(hour_id);
    if (index > -1) {
      selectedHours.splice(index, 1);
    }
    else {
      selectedHours.push(hour_id);
    }
    this.setState({
      selectedHours: selectedHours
    });
  }

  toggleSelectAllHours() {
    if (this.state.selectedAllHours) {
      this.setState({
        selectedAllHours: false,
        selectedHours: []
      });
    }
    else {
      let selectedHours = [];
      for (var i = this.props.hours.length - 1; i > -1; i--) {
        selectedHours.push(this.props.hours[i].id);
      }
      this.setState({
        selectedAllHours: true,
        selectedHours: selectedHours
      });
    }
  }

  handleHourDetailsRowClick = (event) => {
    if (!event.is_editable) {
      toast(
        {
          title: "Hours Approval",
          description: "The Approval period is closed for the selected hour.",
          icon: "close",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    }
  }


  handleOnApprovalHours() {
    this.setState({
      modalApproveHoursActive: true
    });
  }

  onModalApproveHoursClose() {
    this.setState({
      modalApproveHoursActive: false
    });
  }

  onConfirmApproveHours() {
    const month = this.state.activePeriod.month;
    const year = this.state.activePeriod.year;
    const user = this.props.user_id;
    const projectId = this.state.selectedProject.project_id;

    let hours = [], payload;

    for (var i = this.state.selectedHours.length - 1; i > -1; i--) {
      for (var j = this.state.filteredHours.length - 1; j > -1; j--) {
        if (this.state.selectedHours[i] === this.state.filteredHours[j]._original.id) {
          let hour = { id: this.state.filteredHours[j]._original.id, pm_approval_status: 'approved', reject_note: '' };
          hours.push(hour);
        }
      }
    }

    if (hours.length === 0) {
      toast(
        {
          title: "Hours Approval",
          description: "Please select a row.",
          icon: "check",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );

      this.setState({
        modalApproveHoursActive: false
      });

      return;
    }

    if (this.state.selectedProject.remaining_revenues && this.state.selectedProject.remaining_revenues < 0) {
      toast(
        {
          title: "Hours Approval",
          description: "You've reached your remaining revenues. Please update your budget!",
          icon: "check",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );

      this.setState({
        modalApproveHoursActive: false
      });

      return;
    }


    payload = JSON.parse(JSON.stringify(hours))

    this.props.approveHours('approve', month, year, user, projectId, payload);

    this.setState({
      selectedAllHours: false,
      selectedHours: [],
      modalApproveHoursActive: false
    });
  }

  handleOnRejectHours() {
    this.setState({
      modalRejectHoursActive: true
    });
  }

  onModalRejectHoursClose() {
    this.setState({
      modalRejectHoursActive: false
    });
  }

  onConfirmRejectHours() {
    const month = this.state.activePeriod.month;
    const year = this.state.activePeriod.year;
    const user = this.props.user_id;
    const projectId = this.state.selectedProject.project_id;
    const reason = this.state.hoursRejectReason;

    let hours = [], payload;

    for (var i = this.state.selectedHours.length - 1; i > -1; i--) {
      for (var j = this.state.filteredHours.length - 1; j > -1; j--) {
        if (this.state.selectedHours[i] === this.state.filteredHours[j]._original.id) {
          let hour = { id: this.state.filteredHours[j]._original.id, pm_approval_status: 'rejected', reject_note: reason };
          hours.push(hour);
        }
      }
    }

    if (hours.length === 0) {
      toast(
        {
          title: "Hours Reject",
          description: "Please select a row.",
          icon: "x",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );

      this.setState({
        modalRejectHoursActive: false
      });

      return;
    }

    payload = JSON.parse(JSON.stringify(hours));

    this.props.approveHours('reject', month, year, user, projectId, payload);

    this.setState({
      selectedAllHours: false,
      selectedHours: [],
      modalRejectHoursActive: false,
      hoursRejectReason: ''
    });
  }

  handleRejectHoursReasonChange = (e, { name, value }) => {
    this.setState({
      hoursRejectReason: value
    });
  };

  handleOnResetHours() {
    this.setState({
      modalResetHoursActive: true
    });
  }

  onModalResetHoursClose() {
    this.setState({
      modalResetHoursActive: false
    });
  }

  onConfirmResetHours() {
    const month = this.state.activePeriod.month;
    const year = this.state.activePeriod.year;
    const user = this.props.user_id;
    const projectId = this.state.selectedProject.project_id;

    let hours = [], payload;

    for (var i = this.state.selectedHours.length - 1; i > -1; i--) {
      for (var j = this.state.filteredHours.length - 1; j > -1; j--) {
        if (this.state.selectedHours[i] === this.state.filteredHours[j]._original.id) {
          let hour = { id: this.state.filteredHours[j]._original.id, pm_approval_status: 'pending', reject_note: '' };
          hours.push(hour);
        }
      }
    }

    if (hours.length === 0) {
      toast(
        {
          title: "Hours Reset",
          description: "Please select a row.",
          icon: "check",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );

      this.setState({
        modalResetHoursActive: false
      });

      return;
    }

    payload = JSON.parse(JSON.stringify(hours));

    this.props.approveHours('reset', month, year, user, projectId, payload);

    this.setState({
      selectedAllHours: false,
      selectedHours: [],
      modalResetHoursActive: false
    });
  }

  handleOnExportHours() {
    const month = this.state.activePeriod.month;
    const year = this.state.activePeriod.year;
    const projectId = this.state.selectedProject.project_id;

    let ids_list = [];

    for (var i = this.state.selectedHours.length - 1; i > -1; i--) {
      for (var j = this.state.filteredHours.length - 1; j > -1; j--) {
        if (this.state.selectedHours[i] === this.state.filteredHours[j]._original.id) {
          let hour_id = this.state.filteredHours[j]._original.id;
          ids_list.push(hour_id);
        }
      }
    }

    if (ids_list.length === 0) {
      toast(
        {
          title: "Hours Export",
          description: "Please select a row.",
          icon: "check",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
      return;
    }

    toast(
      {
        title: "Hours Export",
        description: "Report is being generated, please wait.",
        type: "info",
        time: constants.TOAST_WARNING_TIMEOUT,
        animation: 'fly left'
      }
    );

    this.props.exportData("Hour", month, year, projectId, "", ids_list);
  }


  // Gestione tabella expenses

  toggleExpensesRow(expense_id) {
    let selectedExpenses = this.state.selectedExpenses.slice(0);
    var index = selectedExpenses.indexOf(expense_id);
    if (index > -1) {
      selectedExpenses.splice(index, 1);
    }
    else {
      selectedExpenses.push(expense_id);
    }
    this.setState({
      selectedExpenses: selectedExpenses
    });
  }

  toggleSelectAllExpenses() {
    if (this.state.selectedAllExpenses) {
      this.setState({
        selectedAllExpenses: false,
        selectedExpenses: []
      });
    }
    else {
      let selectedExpenses = [];
      for (var i = this.props.expenses.length - 1; i > -1; i--) {
        selectedExpenses.push(this.props.expenses[i].id);
      }
      this.setState({
        selectedAllExpenses: true,
        selectedExpenses: selectedExpenses
      });
    }
  }

  handleExpenseDetailsRowClick = (event) => {
    if (!event.is_editable) {
      toast(
        {
          title: "Expenses Approval",
          description: "The Approval period is closed for the selected expense.",
          icon: "close",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    }
  }

  handleAttachClick = (event) => {
    Storage.get(event, {
      level: "public"
    })
      .then(url => {
        window.open(url, '_blank');
      })
      .catch(error => {
        console.log("[ERROR] cannot fetch receipt from S3", error);
      });

  }

  handleOnApprovalExpenses() {
    this.setState({
      modalApproveExpensesActive: true
    });
  }

  onModalApproveExpensesClose() {
    this.setState({
      modalApproveExpensesActive: false
    });
  }

  onConfirmApproveExpenses() {
    const month = this.state.activePeriod.month;
    const year = this.state.activePeriod.year;
    const user = this.props.user_id;
    const projectId = this.state.selectedProject.project_id;

    let expenses = [], payload;

    for (var i = this.state.selectedExpenses.length - 1; i > -1; i--) {
      for (var j = this.state.filteredExpenses.length - 1; j > -1; j--) {
        if (this.state.selectedExpenses[i] === this.state.filteredExpenses[j]._original.id) {
          let expense = { id: this.state.filteredExpenses[j]._original.id, expense_status_code: 'OK', reject_note: '' };
          expenses.push(expense);
        }
      }
    }

    if (expenses.length === 0) {
      toast(
        {
          title: "Expenses Approval",
          description: "Please select a row.",
          icon: "close",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );

      this.setState({
        modalApproveExpensesActive: false
      });

      return;
    }

    if (this.props.singleProject && this.props.singleProject.remaining_billable_expenses && this.props.singleProject.remaining_billable_expenses < 0) {
      toast(
        {
          title: "Expenses Approval",
          description: "You've reached your remaining expenses. Please update your budget!",
          icon: "check",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );

      this.setState({
        modalApproveHoursActive: false
      });

      return;
    }

    payload = JSON.parse(JSON.stringify(expenses));
    this.props.approveExpenses('approve', month, year, user, projectId, payload);

    this.setState({
      selectedAllExpenses: false,
      selectedExpenses: [],
      modalApproveExpensesActive: false
    });
  }

  handleOnRejectExpenses() {
    this.setState({
      modalRejectExpensesActive: true
    });
  }

  onModalRejectExpensesClose() {
    this.setState({
      modalRejectExpensesActive: false
    });
  }

  onConfirmRejectExpenses() {
    const month = this.state.activePeriod.month;
    const year = this.state.activePeriod.year;
    const user = this.props.user_id;
    const projectId = this.state.selectedProject.project_id;
    const reason = this.state.expensesRejectReason;

    let expenses = [], payload;

    for (var i = this.state.selectedExpenses.length - 1; i > -1; i--) {
      for (var j = this.state.filteredExpenses.length - 1; j > -1; j--) {
        if (this.state.selectedExpenses[i] === this.state.filteredExpenses[j]._original.id) {
          let expense = { id: this.state.filteredExpenses[j]._original.id, expense_status_code: 'KO_PM', reject_note: reason };
          expenses.push(expense);
        }
      }
    }

    if (expenses.length === 0) {
      toast(
        {
          title: "Expenses Reject",
          description: "Please select a row.",
          icon: "x",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );

      this.setState({
        modalRejectExpensesActive: false
      });

      return;
    }

    payload = JSON.parse(JSON.stringify(expenses));
    this.props.approveExpenses('reject', month, year, user, projectId, payload);

    this.setState({
      selectedAllExpenses: false,
      selectedExpenses: [],
      modalRejectExpensesActive: false,
      expensesRejectReason: ''
    });
  }

  handleRejectExpensesReasonChange = (e, { name, value }) => {
    this.setState({
      expensesRejectReason: value
    });
  };

  handleOnResetExpenses() {
    this.setState({
      modalResetExpensesActive: true
    });
  }

  onModalResetExpensesClose() {
    this.setState({
      modalResetExpensesActive: false
    });
  }

  onConfirmResetExpenses() {
    const month = this.state.activePeriod.month;
    const year = this.state.activePeriod.year;
    const user = this.props.user_id;
    const projectId = this.state.selectedProject.project_id;

    let expenses = [], payload;

    for (var i = this.state.selectedExpenses.length - 1; i > -1; i--) {
      for (var j = this.state.filteredExpenses.length - 1; j > -1; j--) {
        if (this.state.selectedExpenses[i] === this.state.filteredExpenses[j]._original.id) {
          let expense = { id: this.state.filteredExpenses[j]._original.id, expense_status_code: 'PENDING', reject_note: '' };
          expenses.push(expense);
        }
      }
    }

    if (expenses.length === 0) {
      toast(
        {
          title: "Expenses Reset",
          description: "Please select a row.",
          icon: "check",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );

      this.setState({
        modalResetExpensesActive: false
      });

      return;
    }

    payload = JSON.parse(JSON.stringify(expenses));

    this.props.approveExpenses('reset', month, year, user, projectId, payload);

    this.setState({
      selectedAllExpenses: false,
      selectedExpenses: [],
      modalResetExpensesActive: false
    });
  }

  handleOnExportExpenses() {
    const month = this.state.activePeriod.month;
    const year = this.state.activePeriod.year;
    const projectId = this.state.selectedProject.project_id;

    let ids_list = [];

    for (var i = this.state.selectedExpenses.length - 1; i > -1; i--) {
      for (var j = this.state.filteredExpenses.length - 1; j > -1; j--) {
        if (this.state.selectedExpenses[i] === this.state.filteredExpenses[j]._original.id) {
          let expense_id = this.state.filteredExpenses[j]._original.id;
          ids_list.push(expense_id);
        }
      }
    }

    toast(
      {
        title: "Expense Export",
        description: "Report is being generated, please wait.",
        type: "info",
        time: constants.TOAST_WARNING_TIMEOUT,
        animation: 'fly left'
      }
    );

    this.props.exportData("Expense", month, year, projectId, "", ids_list);
  }


  handleOnExportExpenseImages() {
    let receipt_list = [];

    for (var i = this.state.selectedExpenses.length - 1; i > -1; i--) {
      for (var j = this.state.filteredExpenses.length - 1; j > -1; j--) {
        if (this.state.selectedExpenses[i] === this.state.filteredExpenses[j]._original.id) {
          let receipt = this.state.filteredExpenses[j]._original.receipt;
          if (receipt !== null && receipt !== "") {
            receipt_list.push(receipt);
          }
        }
      }
    }

    if (receipt_list.length === 0) {
      toast(
        {
          title: "Expense Images Export",
          description: "Please select a row.",
          icon: "x",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
      return;
    }

    createPdf(receipt_list);
  }


  // Gestione Monthly Summary

  handleOnInsertWip() {
    history.push({
      pathname: "/project-planning/budget"
    })
  }

  handleOnExportSummary() {

    const month = this.state.activePeriod.month;
    const year = this.state.activePeriod.year;
    const workingDays = this.state.activePeriod.workingDays;
    const projectId = this.state.selectedProject.project_id;

    let ids_list = [];

    toast(
      {
        title: "Summary Export",
        description: "Report is being generated, please wait.",
        type: "info",
        time: constants.TOAST_WARNING_TIMEOUT,
        animation: 'fly left'
      }
    );

    this.props.exportData("Summary", month, year, projectId, workingDays.toString(10), ids_list)
  }

  handleSummaryRowClick() { }


  handleProjectSearchChange = (e, { value }) => {
    this.setState({
      searchProjectValue: value
    });
    if (!this.props.isSuperAdmin || value.length >= 3) {
      this.props.getApprovalProjectList(this.state.activePeriod.month, this.state.activePeriod.year, this.props.user_id, value);
    }
  };

  getColumns = () => {
    return [
      {
        Header: "Project Code",
        accessor: "code",
        id: "code",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Client",
        accessor: "billing_customer_name",
        id: "billing_customer_name",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Project Type",
        accessor: "type",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          } else return row.type.toString() === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="Time Material">Time Material</option>
            <option value="Monthly Rate">Monthly Rate</option>
            <option value="Investment">Investment</option>
            <option value="Fixed Price">Fixed Price</option>
            <option value="Not Attendance">Not Attendance</option>
            <option value="Internal Project">Internal Project</option>
          </select>
        )
      },
      {
        Header: "Project Lead",
        accessor: "manager",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Status",
        accessor: "status",
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              color: rowInfo && rowInfo.row.status === 'to_approve' ? '#9f3a38' :
                rowInfo && rowInfo.row.status === 'complete' ? '#2c662d' : '#555555'
            },
          };
        },
        Cell: row => {
          switch (row.value) {
            case "to_approve":
              return "To Approve";
            case "complete":
              return "Complete";
            case "not_required":
              return "Not Required";
            default:
              return "";
          }
        },
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          } else return row.status.toString() === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="to_approve">To Approve</option>
            <option value="complete">Complete</option>
            <option value="not_required">Not Required</option>
          </select>
        )
      }
    ];

  };


  getHoursDetailsColumns = () => {
    return [
      {
        id: "checkbox",
        accessor: "selected",
        Cell: ({ original }) => {

          let selectionStatus = false;
          if (this.state.selectedHours != null && this.state.selectedHours.indexOf(original.id) > -1) {
            selectionStatus = true;
          }
          let disabledFlag = false;
          //le ore non sono sempre modificabili
          if (!original.is_editable) {
            disabledFlag = true;
          }

          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={selectionStatus}
              onChange={() => this.toggleHoursRow(original.id)}
              disabled={disabledFlag}
              style={{ cursor: 'pointer' }}
            />
          );
        },
        Header: x => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectedAllHours}
              onChange={() => this.toggleSelectAllHours()}
            />
          );
        },
        Filter: ({ filter, onChange }) => <div></div>,
        sortable: false,
        width: 45,
        style: { textAlign: 'center' }
      },
      {
        Header: "Employee",
        accessor: "employee",
        id: "employee",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "SOW Role",
        accessor: "role",
        id: "role",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Date",
        accessor: d => {
          return moment(d.date)
            .local()
            .format("L")
        },
        id: "date",
        style: { textAlign: 'center' },
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Hour Type",
        accessor: "hour_type",
        id: "hour_type",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Billable Hours",
        accessor: "billable_hours",
        id: "billable_hours",
        style: { textAlign: 'center' },
        Cell: row => {
          return row.value;
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "Non-Billable Hours",
        accessor: "non_billable_hours",
        id: "non_billable_hours",
        style: { textAlign: 'center' },
        Cell: row => {
          return row.value;
        },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              color: rowInfo && rowInfo.row.non_billable_hours > 0 ? '#9f3a38' : '#607c89'
            },
          };
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      // AEP-2113
      {
        Header: "Notes",
        accessor: "notes",
        id: "notes",
        style: { textAlign: 'center', textDecoration: 'underline' },
        Cell: row => {
          return <a onClick={() => this.openNotesModal(row.value)}>See notes</a>
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      // AEP-2113
      {
        Header: "Status",
        accessor: "status",
        style: { textAlign: 'center' },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              color: rowInfo && rowInfo.row.status === 'rejected' ? '#9f3a38' :
                rowInfo && rowInfo.row.status === 'pending' ? '#573a08' : '#2c662d'
            },
          };
        },
        Cell: row => {
          switch (row.value) {
            case "approved":
              return "Approved";
            case "rejected":
              return "Rejected";
            case "pending":
              return "Pending";
            default:
              return "";
          }
        },
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          } else return row.status.toString() === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
            <option value="pending">Pending</option>
          </select>
        )
      }
    ];
  };


  getExpensesDetailsColumns = () => {
    return [
      {
        id: "checkbox",
        accessor: "selected",
        filterMethod: null,
        Cell: ({ original }) => {
          let selectionStatus = false;
          if (this.state.selectedExpenses != null && this.state.selectedExpenses.indexOf(original.id) > -1) {
            selectionStatus = true;
          }
          let disabledFlag = false;
          //le spese non sono sempre modificabili
          if (!original.is_editable) {
            disabledFlag = true;
          }

          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={selectionStatus}
              onChange={() => this.toggleExpensesRow(original.id)}
              disabled={disabledFlag}
              style={{ cursor: 'pointer' }}
            />
          );
        },
        Header: x => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selectedAllExpenses}
              onChange={() => this.toggleSelectAllExpenses()}
            />
          );
        },
        Filter: ({ filter, onChange }) => <div></div>,
        sortable: false,
        width: 45,
        style: { textAlign: 'center' }
      },
      {
        Header: "Employee",
        accessor: "employee",
        id: "employee",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "SOW Role",
        accessor: "role",
        id: "role",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Date",
        accessor: d => {
          return moment(d.date)
            .local()
            .format("L")
        },
        id: "date",
        style: { textAlign: 'center' },
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Expense Type",
        accessor: "expense_type",
        id: "expense_type",
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Amount",
        accessor: "amount",
        id: "amount",
        style: { textAlign: 'right' },
        Cell: row => {
          var converted_amount = row.original.amount * row.original.conversion_rate;
          return parseFloat(converted_amount).toFixed(2);
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "Currency",
        accessor: "currency",
        id: "currency",
        style: { textAlign: 'left' },
        Cell: row => {
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Billable",
        accessor: "billable",
        id: "billable",
        style: { textAlign: 'center' },
        Cell: row => {
          switch (row.value) {
            case true:
              return <Icon name='check' size='large' />;
            default:
              return <Icon name='x' size='large' />;
          }
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "Receipt",
        accessor: "receipt",
        id: "receipts",
        style: { textAlign: 'center' },
        Cell: row => {
          if (row.value === undefined || row.value === null || row.value === "") {
            if (row.original.need_receipt === 1) {
              return <Icon name='x' color='red' size='large' />;
            }
            else return null;
          }
          else {
            return <Icon name='attach' size='large' onClick={() => this.handleAttachClick(row.value)} />;
          }
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "Notes",
        accessor: "notes",
        id: "notes",
        style: { textAlign: 'center', textDecoration: 'underline' },
        Cell: row => {
          return <a onClick={() => this.openNotesModal(row.value)}>See notes</a>
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "Status",
        accessor: "status",
        style: { textAlign: 'center' },
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              color: rowInfo && rowInfo.row.status === 'MR' ? '#9f3a38' :
                rowInfo && rowInfo.row.status === 'PENDING' ? '#573a08' :
                  rowInfo && rowInfo.row.status === 'OK' ? '#2c662d' :
                    rowInfo && rowInfo.row.status === 'KO_PM' ? '#9f3a38' :
                      rowInfo && rowInfo.row.status === 'KO_HR' ? '#9f3a38' :
                        rowInfo && rowInfo.row.status === 'CLOSED' ? '#2c662d' : '#999999'
            },
          };
        },
        Cell: row => {
          return row.original.status_name;
        },
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          } else return row.status.toString() === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="MR">Missing receipt</option>
            <option value="PENDING">Waiting for approval</option>
            <option value="OK">Approved</option>
            <option value="KO_PM">Rejected by PM</option>
            <option value="KO_HR">Rejected by HR</option>
            <option value="CLOSED">Sent to sap</option>
          </select>
        )
      }
    ];
  };


  onHourFiltersUpdated = (data) => {
    console.log("onHourFiltersUpdated");
    console.log("data", data);
    this.setState((prevState) => ({
      ...prevState,
      filteredHours: data
    }));
  }


  onExpenseFiltersUpdated = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      filteredExpenses: data
    }));
  }


  render() {


    const { isNotesModalOpen, selectedNote } = this.state;



    let panes = [];
    if (this.state.hours_approval_enabled_companies.includes(this.props.company_code) ||
      this.state.hours_approval_enabled_companies.includes("ALL")) {

      panes.push(
        {
          menuItem: "Hour Details",
          pane:
            <Tab.Pane key="0">

              <Grid columns={1} stackable>

                <Grid.Row>

                  <React.Fragment>
                    <Grid.Column width={16}>
                      <IconButton onClick={this.handleOnExportHours}
                        loading={this.props.loadingExportData}
                        floated="right"
                        icon="cloud download"
                        label="Export Selected" />
                      <IconButton onClick={this.handleOnResetHours}
                        floated="right"
                        icon="erase"
                        label="Reset Selected" />
                      <IconButton onClick={this.handleOnRejectHours}
                        floated="right"
                        icon="thumbs down outline"
                        label="Reject Selected" />
                      <IconButton onClick={this.handleOnApprovalHours}
                        floated="right"
                        icon="thumbs up outline"
                        label="Approve Selected" />
                    </Grid.Column>
                  </React.Fragment>

                </Grid.Row>

              </Grid>

              <div style={{ marginTop: "20px" }}>
                <ApprovalTable
                  columns={this.getHoursDetailsColumns()}
                  data={this.props.hours}
                  onClick={this.handleHourDetailsRowClick}
                  defaultPageSize={100}
                  setLoadingState={this.setLoadingState}
                  loading={this.props.loadingHours}
                  onFiltersUpdated={this.onHourFiltersUpdated}
                />
              </div>

            </Tab.Pane>
        });
    }

    if (this.state.expenses_approval_enabled_companies.includes(this.props.company_code) ||
      this.state.expenses_approval_enabled_companies.includes("ALL")) {

      panes.push(
        {
          menuItem: "Expense Details",
          pane:
            <Tab.Pane key="1">

              <Grid columns={1} stackable>

                <Grid.Row>

                  <React.Fragment>
                    <Grid.Column width={16}>
                      <IconButton onClick={this.handleOnExportExpenseImages}
                        loading={this.props.loadingExportData}
                        floated="right"
                        icon="cloud download"
                        label="Export Selected Images" />
                      <IconButton onClick={this.handleOnExportExpenses}
                        loading={this.props.loadingExportData}
                        floated="right"
                        icon="cloud download"
                        label="Export Selected Data" />
                      <IconButton onClick={this.handleOnResetExpenses}
                        floated="right"
                        icon="erase"
                        label="Reset Selected" />
                      <IconButton onClick={this.handleOnRejectExpenses}
                        floated="right"
                        icon="thumbs down outline"
                        label="Reject Selected" />
                      <IconButton onClick={this.handleOnApprovalExpenses}
                        floated="right"
                        icon="thumbs up outline"
                        label="Approve Selected" />
                    </Grid.Column>
                  </React.Fragment>

                </Grid.Row>

              </Grid>

              <div style={{ marginTop: "20px" }}>
                <ApprovalTable
                  columns={this.getExpensesDetailsColumns()}
                  data={this.props.expenses}
                  onClick={this.handleExpenseDetailsRowClick}
                  defaultPageSize={100}
                  setLoadingState={this.setLoadingState}
                  loading={this.props.loadingExpenses}
                  onFiltersUpdated={this.onExpenseFiltersUpdated}
                />
              </div>

            </Tab.Pane>
        });
    }

    return (
      <Container>

        {/* AEP-2113 */}
        {/* Modal per le note */}

        <Modal open={isNotesModalOpen} onClose={this.closeNotesModal} size="small">
          <Header icon="sticky note" content="Note" />
          <Modal.Content>
            <p>{selectedNote ? selectedNote : 'No notes'}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.closeNotesModal}>
              <Icon name="checkmark" /> Ok
            </Button>
          </Modal.Actions>
        </Modal>
        {/* AEP-2113 */}

        <Dimmer active={this.props.loadingHours || this.props.loadingExpenses} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <Can I="approval:Read" a="all">
          {this.state.selectedProject && this.props.singleProject &&
            <React.Fragment>
              <Segment>
                <div>
                  <Button icon className="cancel" onClick={() => this.clearProjectSelected()}><Icon name="arrow left" /></Button>
                </div>
                <Header floated='right'>
                  {monthStr[this.state.activePeriod.month - 1]} {this.state.activePeriod.year}
                </Header>
                <Header floated='left'>
                  Project: {this.props.singleProject.billing_customer_name} - {this.props.singleProject.description}
                </Header>

                <ApprovalModal
                  object='hours'
                  type='approve'
                  open={this.state.modalApproveHoursActive}
                  handleClose={this.onModalApproveHoursClose}
                  confirmApprove={this.onConfirmApproveHours}
                  reason=''
                  rejectReasonChange={() => { }}
                />

                <ApprovalModal
                  object='hours'
                  type='reject'
                  open={this.state.modalRejectHoursActive}
                  handleClose={this.onModalRejectHoursClose}
                  confirmApprove={this.onConfirmRejectHours}
                  reason={this.state.hoursRejectReason}
                  rejectReasonChange={this.handleRejectHoursReasonChange}
                />

                <ApprovalModal
                  object='hours'
                  type='reset'
                  open={this.state.modalResetHoursActive}
                  handleClose={this.onModalResetHoursClose}
                  confirmApprove={this.onConfirmResetHours}
                  reason=''
                  rejectReasonChange={() => { }}
                />

                <ApprovalModal
                  object='expenses'
                  type='approve'
                  open={this.state.modalApproveExpensesActive}
                  handleClose={this.onModalApproveExpensesClose}
                  confirmApprove={this.onConfirmApproveExpenses}
                  reason=''
                  rejectReasonChange={() => { }}
                />

                <ApprovalModal
                  object='expenses'
                  type='reject'
                  open={this.state.modalRejectExpensesActive}
                  handleClose={this.onModalRejectExpensesClose}
                  confirmApprove={this.onConfirmRejectExpenses}
                  reason={this.state.expensesRejectReason}
                  rejectReasonChange={this.handleRejectExpensesReasonChange}
                />

                <ApprovalModal
                  object='expenses'
                  type='reset'
                  open={this.state.modalResetExpensesActive}
                  handleClose={this.onModalResetExpensesClose}
                  confirmApprove={this.onConfirmResetExpenses}
                  reason=''
                  rejectReasonChange={() => { }}
                />

                <ProjectHeader
                  selectedProject={this.props.singleProject ? this.props.singleProject : {}}
                  hoursApprovalStatus={this.state.hoursApprovalStatus}
                  expensesApprovalStatus={this.state.expensesApprovalStatus}
                />

                <Divider />

                <ProjectSummaryComponent
                  selectedProject={this.props.singleProject ? this.props.singleProject : {}}
                  insertWip={this.handleOnInsertWip}
                  exportSummary={this.handleOnExportSummary}
                  projectSummary={this.props.projectSummary}
                  summaryRowClick={this.handleSummaryRowClick}
                  setLoadingState={this.setLoadingState}
                  loading={this.props.loadingProjectSummary}
                  loadingExportData={this.props.loadingExportData}
                  workingDays={this.state.activePeriod.workingDays}
                  hoursPerDay={this.state.hoursPerDay}
                />

                <Divider />

                <Tab
                  renderActiveOnly={false}
                  onTabChange={this.handleTabChange}
                  defaultActiveIndex={this.state.activeTabIndex}
                  panes={panes}
                />

              </Segment>
            </React.Fragment>
          }
          {!this.state.selectedProject && this.props.projects &&
            <React.Fragment>
              <Header>Project Approval</Header>
              <Segment clearing>
                <Form>
                  <Form.Group>
                    <div className='two wide field'>
                      <label>Month</label>
                      <DatePicker
                        selected={this.state.selectedDate}
                        onChange={date => this.setStartDate(date)}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                      />
                    </div>
                  </Form.Group>

                  <Form.Group >
                    <div className='four wide field'>
                      <label>Project</label>
                      <Search
                        name="search"
                        input={{ fluid: true }}
                        onSearchChange={this.handleProjectSearchChange}
                        placeholder={"Search all projects..."}
                        showNoResults={false}
                        minCharacters={3}
                        size="large"
                        loading={this.props.loading}
                        value={this.state.searchProjectValue}
                      />
                    </div>
                  </Form.Group>

                </Form>

                <div style={{ marginTop: "20px" }}>
                  <ReusableTable
                    columns={this.getColumns()}
                    data={this.props.projects}
                    onClick={this.handleRowClick}
                    defaultPageSize={10}
                    setLoadingState={this.setLoadingState}
                    loading={this.props.loading}
                  />
                </div>

              </Segment>

            </React.Fragment>
          }
        </Can>
      </Container>
    );
  }
}

function mapStateToProps(state) {

  let projectSummary = [];

  let defaultCurrency = '';

  let projects = _.toArray(
    _.mapValues(state.approvalProjectReducer.projects, o => {
      var status = o.status;

      defaultCurrency = o.currency;
      return {
        project_id: o.project_id,
        code: o.code,
        billing_customer_name: o.billing_customer_name,
        description: o.description,
        type: o.type,
        manager: o.manager_name + ' ' + o.manager_surname,
        month: monthStr[o.month - 1] + ' ' + o.year,
        status: status,
        currency: o.currency,
        corporate: o.corporate,
        approving_period: o.approving_period_from + ' - ' + o.approving_period_to,

        remaining_revenues: o.remaining_revenues,
        remaining_billable_expenses: o.remaining_billable_expenses,
        run_rate: o.run_rate
      };
    })
  );


  let hours = state.approvalProjectReducer.hours;

  for (var i = hours.length - 1; i > -1; i--) {

    let employee_found = false;
    for (var j = projectSummary.length - 1; j > -1; j--) {
      if ((hours[i].employee_name + ' ' + hours[i].employee_surname) === projectSummary[j].employee) {
        projectSummary[j].tot_hours = projectSummary[j].tot_hours + hours[i].hours;
        if (hours[i].sales_level === 'NB' || hours[i].is_billable == false) {
          projectSummary[j].non_billable_hours = projectSummary[j].non_billable_hours + hours[i].hours;
        }
        else {
          projectSummary[j].billable_hours = projectSummary[j].billable_hours + hours[i].hours;
        }
        employee_found = true;
      }
    }
    if (!employee_found) {
      let projectSummaryElement =
      {
        "employee": hours[i].employee_name + ' ' + hours[i].employee_surname,
        "role": hours[i].role,
        "tot_hours": hours[i].hours,
        "fte_eq": '',
        "billable_hours": (hours[i].sales_level === 'NB' || hours[i].is_billable == false) ? 0 : hours[i].hours,
        "non_billable_hours": (hours[i].sales_level === 'NB' || hours[i].is_billable == false) ? hours[i].hours : 0,
        "billable_expenses": 0,
        "non_billable_expenses": 0,
        "currency": defaultCurrency
      };
      projectSummary.push(projectSummaryElement);
    }
  }


  let expenses = state.approvalProjectReducer.expenses;

  for (let i = expenses.length - 1; i > -1; i--) {

    var converted_amount = expenses[i].amount * expenses[i].conversion_rate;

    let employee_found = false;
    for (let j = projectSummary.length - 1; j > -1; j--) {
      if ((expenses[i].employee_name + ' ' + expenses[i].employee_surname) === projectSummary[j].employee) {
        projectSummary[j].tot_expenses = projectSummary[j].tot_expenses + converted_amount;
        if (expenses[i].sales_level === 'NB' || expenses[i].is_billable == false) {
          projectSummary[j].non_billable_expenses = projectSummary[j].non_billable_expenses + converted_amount;
        }
        else {
          projectSummary[j].billable_expenses = projectSummary[j].billable_expenses + converted_amount;
        }
        projectSummary[j].currency = expenses[i].currency;
        employee_found = true;
      }
    }
    if (!employee_found) {
      let projectSummaryElement =
      {
        "employee": expenses[i].employee_name + ' ' + expenses[i].employee_surname,
        "role": expenses[i].role,
        "tot_hours": 0,
        "fte_eq": '',
        "billable_hours": 0,
        "non_billable_hours": 0,
        "billable_expenses": expenses[i].sales_level === 'NB' || expenses[i].is_billable == false ? 0 : converted_amount,
        "non_billable_expenses": expenses[i].sales_level === 'NB' || expenses[i].is_billable == false ? converted_amount : 0,
        "currency": expenses[i].currency
      };
      projectSummary.push(projectSummaryElement);
    }
  }


  let isSuperAdmin = false;
  let user_groups = state.authReducer.user_groups;
  if (user_groups !== undefined) {
    let filtered = user_groups.filter(e => e === 'ia-superadmin'); // 'ia-projectmanager'); // 'ia-superadmin');
    if (filtered.pop())
      isSuperAdmin = true;
  }

  let singleProject = {};
  if (!_.isEmpty(state.approvalProjectReducer.singleProject)) {
    let projectStatus = (state.approvalProjectReducer.singleProject.hours_total > 0 || state.approvalProjectReducer.singleProject.expenses_total > 0 ? (state.approvalProjectReducer.singleProject.hours_not_approved > 0 || state.approvalProjectReducer.singleProject.expenses_not_approved > 0 ? 'to_approve' : 'complete') : 'not_required');
    singleProject = {
      //mapping 1:1
      project_id: state.approvalProjectReducer.singleProject.project_id,
      code: state.approvalProjectReducer.singleProject.code,
      billing_customer_name: state.approvalProjectReducer.singleProject.billing_customer_name,
      description: state.approvalProjectReducer.singleProject.description,
      type: state.approvalProjectReducer.singleProject.type,
      currency: state.approvalProjectReducer.singleProject.currency,
      corporate: state.approvalProjectReducer.singleProject.corporate,
      remaining_revenues: state.approvalProjectReducer.singleProject.remaining_revenues,
      remaining_billable_expenses: state.approvalProjectReducer.singleProject.remaining_billable_expenses,
      run_rate: state.approvalProjectReducer.singleProject.run_rate,
      //calculated_fields
      manager: state.approvalProjectReducer.singleProject.manager_name + ' ' + state.approvalProjectReducer.singleProject.manager_surname,
      month: monthStr[state.approvalProjectReducer.singleProject.month - 1] + ' ' + state.approvalProjectReducer.singleProject.year,
      approving_period: state.approvalProjectReducer.singleProject.approving_period_from + ' - ' + state.approvalProjectReducer.singleProject.approving_period_to,
      status: projectStatus,

    }
  }


  return {
    cn: state.authReducer.cn,
    user_id: state.authReducer.user_id,
    user_details: state.authReducer.user_details,
    user_groups: state.authReducer.user_groups,
    company_code: state.authReducer.company_code,
    loading: state.approvalProjectReducer.loading,
    loadingHours: state.approvalProjectReducer.loadingHours,
    loadingExpenses: state.approvalProjectReducer.loadingExpenses,
    loadingExportData: state.approvalProjectReducer.loadingExportData,

    loadingSingleProject: state.approvalProjectReducer.loadingSingleProject,
    singleProject: singleProject,

    isSuperAdmin: isSuperAdmin,


    projects: projects,

    hours:
      _.toArray(
        _.mapValues(state.approvalProjectReducer.hours, o => {

          return {
            selected: false,
            id: o.id,
            employee: o.employee_name + ' ' + o.employee_surname,
            role: o.role,
            date: o.date,
            hour_type: o.hour_type,
            billable_hours: o.sales_level === 'NB' || o.is_billable == false ? 0 : o.hours,
            non_billable_hours: o.sales_level === 'NB' || o.is_billable == false ? o.hours : 0,
            status: o.pm_approval_status,
            is_editable: o.is_editable,
            notes: o.notes
          };
        })
      ),

    expenses:
      _.toArray(
        _.mapValues(state.approvalProjectReducer.expenses, o => {

          return {
            selected: false,
            id: o.id,
            employee: o.employee_name + ' ' + o.employee_surname,
            role: o.role,
            date: o.date,
            expense_type: o.expense_type,
            amount: o.amount,
            currency: o.currency,
            billable: o.sales_level === 'NB' ? false : true,
            receipt: o.receipt,
            notes: o.notes,
            status: o.expense_status_code,
            status_name: o.expense_status_name,
            need_receipt: o.need_receipt,
            conversion_rate: o.conversion_rate,
            is_editable: o.is_editable
          };
        })
      ),

    projectSummary: projectSummary,


  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetProjectSearch: () => dispatch(resetProjectSearch()),
    resetProject: () => dispatch(resetProject()),
    getApprovalProjectList: (month, year, user, searchProjectValue) => dispatch(startGetApprovalProjectList(month, year, user, searchProjectValue)),
    getApprovalProject: (month, year, user, projectId) => dispatch(startGetApprovalProject(month, year, user, projectId)),
    getApprovalHourList: (month, year, user, projectId) => dispatch(startGetApprovalHourList(month, year, user, projectId)),
    approveHours: (command, month, year, user, projectId, params) => dispatch(startApproveHours(command, month, year, user, projectId, params)),
    getApprovalExpenseList: (month, year, user, projectId) => dispatch(startGetApprovalExpenseList(month, year, user, projectId)),
    approveExpenses: (command, month, year, user, projectId, params) => dispatch(startApproveExpenses(command, month, year, user, projectId, params)),
    exportData: (type, month, year, projectId, workingDays, ids_list) => dispatch(startExportData(type, month, year, projectId, workingDays, ids_list))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalProjectContainer);


async function createPdf(receipt_list) {


  const pdfDoc = await PDFDocument.create()

  var i;

  for (i = 0; i < receipt_list.length; i++) {

    var receipt = receipt_list[i];

    const imageBytes = await loadImageData(receipt);

    const mimeType = await detectMimeType(imageBytes);


    var image;

    if (mimeType === "image/jpeg" || mimeType === "image/png") {

      if (mimeType === "image/jpeg") {
        image = await pdfDoc.embedJpg(imageBytes)
      }
      else if (mimeType === "image/png") {
        image = await pdfDoc.embedPng(imageBytes)
      }
      else {
        continue;
      }

      const imageDims = image.scale(1)

      const newPage = pdfDoc.addPage()

      let i_width = imageDims.width,
        i_width_new = i_width,
        i_height = imageDims.height,
        i_height_new = i_height,
        p_width = newPage.getWidth(),
        p_height = newPage.getHeight(),
        k_width = i_width / p_width,
        k_height = i_height / p_height

      if (k_width > k_height) {
        if (k_width > 0.9) {
          i_width_new = p_width * 0.9
          i_height_new = i_width_new * i_height / i_width;
        }
        if (k_width < 0.5) {
          i_width_new = p_width * 0.5
          i_height_new = i_width_new * i_height / i_width;
        }
      }
      else {
        if (k_height > 0.9) {
          i_width_new = i_height_new * i_width / i_height;
          i_height_new = p_height * 0.9;
        }
        if (k_width < 0.5) {
          i_width_new = i_height_new * i_width / i_height;
          i_height_new = p_height * 0.5;
        }
      }

      newPage.drawImage(image, {
        x: p_width / 2 - i_width_new / 2,
        y: p_height / 2 - i_height_new / 2,
        width: i_width_new,
        height: i_height_new,
      })
    }
    else if (mimeType === "application/pdf") {

      const pdfToEmbed = await PDFDocument.load(imageBytes, { ignoreEncryption: true })
      const pageIndices = Array.from(pdfToEmbed.getPages().keys());
      const copiedPages = await pdfDoc.copyPages(pdfToEmbed, pageIndices);

      copiedPages.forEach((page) => {
        pdfDoc.addPage(page);
      });
    }
  }

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save()


  const url = window.URL.createObjectURL(new Blob([pdfBytes]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'export.pdf');
  document.body.appendChild(link);
  link.click();
}


async function loadImageData(receipt) {

  let url = await Storage.get(receipt, {
    level: "public"
  });
  let res = await fetch(url).then((res) => res.arrayBuffer())

  return res;
}


function detectMimeType(data) {
  var arr = (new Uint8Array(data)).subarray(0, 4);
  var header = '';
  for (var i = 0; i < arr.length; i++) {
    header += arr[i].toString(16);
  }
  // Check the file signature against known types
  var type = 'unknown';
  switch (header) {
    case '89504e47':
      type = 'image/png';
      break;
    case '47494638':
      type = 'image/gif';
      break;
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
      type = 'image/jpeg';
      break;
    case '25504446':
    default:
      type = 'application/pdf';
      break;
  }
  return type;
}