import React from "react";
//import { connect } from "react-redux";
import {
  Button,
  Form,
  Grid,
  Modal,
  Search,
  Segment,
  Label,
  Popup,
  Divider,
  Header,
  Dropdown,
} from "semantic-ui-react";
import IconButton from "../../../components/shared/buttons/IconButton";
import constants from "../../../utils/constants";
import { toast } from "react-semantic-toasts";
import PaginatedReusableTable from "../../../components/shared/PaginatedReusableTable";
import { amISuperAdmin } from "../../../utils/functionUtils";

import { history } from "../../../routers/AppRouters";

class ResearchComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      searchValue: "",
      currPage: 0,
      exampleData: [{ key: "value" }],
      filtersId: {
        nameSurname: "",
        office: null,
        company: null,
        internalLevel: null,
        language: null,
        deputyManager: null,
        manager: null,
        unit: null,
        softSkill: null,
        technicalSkill: null,
        certification: null,
        practice: null,

        customer: null,
        //startDate: moment().startOf("year"),
        //endDate: moment().endOf("year").set("date",moment().daysInMonth())
      },
      customer_key: null,
      categoryId: "",
      selectedCategory: false,
      categoryName: "",
      user_id: 0,
      downloadCvClicked: false,
      openModalCV: false,
    };
  }

  researchCols() {
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Surname",
        accessor: "surname",
      },
      {
        Header: "CV",
        Cell: () => (
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
              marginRight: "20px",
            }}
          >
            <Button onClick={this.openModalCV}>Download CV</Button>
          </div>
        ),
      },
    ];
  }

  handleSearch = (e, { value }) => {
    let filters = this.state.filtersId;
    console.log("nome cercato",value)

    this.setState((prevState) => ({
      ...prevState,
      searchValue: value,
      filtersId: {
        ...prevState.filtersId,
        nameSurname: value,
      },
    }));

    //console.log(value);
    ///console.log(this.state.filtersId.nameSurname);
    filters.nameSurname = value;
    //console.log(filters);

    if (value.length > 2) {
      this.props.getResearch(this.state.filtersId);
    }
  };

  searchCV = () => {
    let filter = this.state.filtersId;
    //filter.technicalSkill = [3];
    this.props.getResearch(filter);
    // this.props.getResearch(this.state.filtersId);
    //console.log("Search with ", filter);
  };

  toggleVisibility = () => {
    //console.log("Click filter ", this.state.visible);
    this.setState((prevState) => ({
      visible: !prevState.visible,
    }));

    this.props.reloadSoftSkills();
    this.props.getTechnicalSkills(this.state.categoryId);
    this.props.getLanguages(this.props.language);

    // console.log("State visibile : ", this.state.visible);
  };


  onSelectFilterChange = (e, data) => {
  //  console.log("data selected ", data, "data.value ", data.value, "data.options ", data.options);

    const name = data.name; // es: "softSkill"
   // console.log("name ", name);

    let valueList = data.value; //es: ["java","node"]
    let value = [];

    if (name === "office") {
      this.setState((prevState) => ({
        filtersId: {
          ...prevState.filtersId,
          [name]: data.value,
        },
      }));
    } else if (
      name==="practice" ||
      name==="deputyManager" ||
      name==="manager"

    ) {
      valueList.forEach((e) => {
        data.options.map((o) => {
          if (o.value === e) {
            value.push(o.code || o.value);
          }
          return null;
        });
        return null;
      });

      //console.log("name ", name, "value ", value);

      this.setState((prevState) => ({
        filtersId: {
          ...prevState.filtersId,
          [name]: value,
        },
      }));
    }else {
      valueList.forEach((e) => {
        data.options.map((o) => {
          if (o.value === e) {
            value.push(o.key);
          }
          return null;
        });
      });

      console.log("name ", name, "value ", value);

      this.setState((prevState) => ({
        filtersId: {
          ...prevState.filtersId,
          [name]: value,
        },
      }));
    }

    // console.log("filters id selected ", this.state.filtersId);
  };

  resetFilters = () => {
    //console.log("click reset filers");

    /*Array.from(document.querySelectorAll("a.ui.label")).forEach(
      input => 
      (
        input.text = null
        //console.log(input.text)
        
      )
    );*/

    this.setState((prevState) => ({
      ...prevState,
      filtersId: {
        nameSurname: "",
        office: null,
        company: null,
        internalLevel: null,
        language: null,
        deputyManager: null,
        unit: null,
        manager: null,
        softSkill: null,
        technicalSkill: null,
        certification: null,
        practice: null,
        subPractice: null,
        customer: null,
      },

      visible: !prevState.visible,
    }));

    //window.location.reload();
  };
  
  /*onStartDateChange=(date)=>{
    console.log("date ",date)
    if(moment(date)<this.state.filtersId.endDate){
    this.setState((prevState)=>({
      filtersId:{
      ...prevState.filtersId,
      startDate:moment(date)
    }
    }))}
    else{toast({
      title: "Research",
      description: "Start date must be before the end date",
      type: "error",
      icon: "file",
      time: constants.TOAST_ERROR_TIMEOUT,
      animation: "fly left",
    })}
  }

  onEndDateChange=(date)=>{
    console.log("date ",date)
    if(moment(date)>this.state.filtersId.startDate){
    this.setState((prevState)=>({
      filtersId:{
      ...prevState.filtersId,
      endDate:moment(date).set("date", moment(date, "YYYY-MM-DD").daysInMonth()
      )
    }
    }))}
    else{toast({
      title: "Research",
      description: "End date must be after the start date",
      type: "error",
      icon: "file",
      time: constants.TOAST_ERROR_TIMEOUT,
      animation: "fly left",
    })}
  }*/

  onSelectRow = (clickedRow) => {
    this.props.researchRaw.map((row) => {
      if (row.id === clickedRow.id) {
        /*
        this.setState({
          ...prevState,
          user_id: clickedRow.id,
          unique_id: clickedRow.unique_id
        })
        //this.state.user_id = clickedRow.id;
        //this.state.unique_id = clickedRow.unique_id;
        */
        this.setState((prevState) => ({
          ...prevState,
          user_id: clickedRow.id,
          unique_id: clickedRow.unique_id
        }));
      
        const str =
          clickedRow.unique_id +
          "-" +
          clickedRow.unique_id +
          "-" +
          clickedRow.name +
          "-" +
          clickedRow.surname;
        if (this.state.downloadCvClicked === false) {
          history.push("/cvGenerator/" + str);
        } else {
          console.log("Clicked button!");
        }
      }
      return null;
    });

    /* in this.props.researchRaw ho:
        id: 5051
        name: "Salvatore"
        surname: "Sbirziola"
        unique_id: 1459
      */
  };

  downloadDocsCv = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response)
        description = "Error creating CV document. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "CV Docs",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "CV Docs",
        description: "Successfully created.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.createDocs(
      this.state.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeModalCV();
  };

  selectCategory = (e, data) => {
    const name = data.value; // es: "categoria 1"

    data.options.map((opt) => {
      if (opt.value === name) {
        this.setState((prevState) => ({
          ...prevState,
          categoryId: opt.key,
          selectedCategory: true,
          categoryName: opt.value
        }))
        this.props.getTechnicalSkills(opt.key);

        //this.state.categoryId = opt.key;
        //this.props.getTechnicalSkills(this.state.categoryId);
        //this.state.selectedCategory = true;
        //this.state.categoryName = opt.value;
      }
      return null;
    });
  };

  openModalCV = () => {
    //this.state.downloadCvClicked = true;
    //this.state.openModalCV = true;

    this.setState((prevState) => ({
      ...prevState,
      openModalCV: true,
      downloadCvClicked: true,
    }));

    return null;
  };

  closeModalCV = () => {
    //this.state.downloadCvClicked = false;
    //this.state.openModalCV = false;

    this.setState((prevState) => ({
      ...prevState,
      openModalCV: false,
      downloadCvClicked: false,
    }));

    return null;
  };



  deleteDuplicate = (allValues) => {

    // array of names NOT duplicated. ["ORGANIZATION DEV & TALENT MANAGEMENT-UN_0001", "INTERNAL AUDIT-UN_0076", "ADMINISTRATION FINANCE CONTROL-UN_0002" ...]
    let singleValuesName = [];

    //
    let newValues = []; // array of objects not duplicated

    allValues.map(v => {
      if (!singleValuesName.includes(v.text)) {
        singleValuesName.push(v.text)
      }
      return null;
    })


    for (let svn in singleValuesName) {
      for (let av in allValues) {

        if ("text" in allValues[av] && singleValuesName[svn] && allValues[av].text === singleValuesName[svn]) {
          newValues.push(allValues[av])
          break
        }
      }
    }

    newValues.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0));


    return newValues;

  }


  render() {

    let data = this.props.researchRaw;

    if (amISuperAdmin(this.props.auth.user_groups) && this.props.warningPopupClicked === false){
      this.props.updatePopupClicked();
    }

    ///console.log("data", data);
    /*
    let avatar = "";
    let username = "";
    let email = "";
    let userId = "";
    let uniqueId = "";
    //console.log("user auth info ", this.props.auth);

    if (this.props.auth && this.props.auth.user) {
      avatar = this.props.auth.user.attributes.picture;
      username = this.props.auth.user.attributes.name;
      email = this.props.auth.user.attributes.email;
      userId = this.props.auth.user_id;
      uniqueId = this.props.auth.unique_id;
    }
    */

    return (
      <div style={{ marginTop: "10px" }}>
        <Label
          className="label-icon"
          style={{ marginLeft: "5px", display: "inline-block" }}
        >
          Search
        </Label>
        <Popup
          content={"Enter consultant Name and Surname... "}
          trigger={
            <Label color="teal" circular>
              ?
            </Label>
          }
        />
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <Search
                input={{ fluid: true }}
                onSearchChange={this.handleSearch}
                placeholder={"Enter Name and Surname..."}
                showNoResults={false}
                minCharacters={3}
                loading={this.props.loading}
                value={this.state.searchValue}
                name="nameSurname"
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Button
                basic
                style={{ margin: "0px 10px 0px 5px" }}
                content={"Filters"}
                onClick={this.toggleVisibility}
                color="teal"
                floated="left"
                icon="filter"
              />
              {this.state.visible === true ? (
                <Button
                  basic
                  style={{ margin: "0px 10px 0px 5px" }}
                  content={"Reset filters"}
                  onClick={this.resetFilters}
                  color="teal"
                  floated="left"
                  icon="filter"
                />
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.state.visible === true ? (
          <Segment clearing secondary>
            <div
              style={{
                marginTop: "2rem",
                marginLeft: "2rem",
                marginRight: "2rem",
              }}
            >
              <Grid>
                <Grid.Row>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>Certification</label>
                        <Dropdown
                          placeholder="Select..."
                          multiple
                          search
                          clearable
                          selection
                          options={this.props.certificationsOptions}
                          onChange={this.onSelectFilterChange}
                          name="certification"
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>Role</label>
                        <Dropdown
                          placeholder="Select..."
                          multiple
                          search
                          clearable
                          selection
                          options={this.props.roleOptions}
                          onChange={this.onSelectFilterChange}
                          name="role"
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>Sector</label>
                        <Dropdown
                          placeholder="Select..."
                          multiple
                          search
                          clearable
                          selection
                          options={this.props.sectorOptions}
                          onChange={this.onSelectFilterChange}
                          name="sector"
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>Soft Skill</label>
                        <Dropdown
                          placeholder="Select..."
                          name="softSkill"
                          multiple
                          search
                          clearable
                          selection
                          /*options={this.concatSkill(
                            this.props.softSkillsOptions,
                            this.props.userSoftSkillsOptions
                          )}*/
                          options={this.props.softSkillsOptions}
                          onChange={this.onSelectFilterChange}
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>Category of Technical Skill</label>
                        <Dropdown
                          placeholder="Select..."
                          search
                          selection
                          multiple={false}
                          options={this.props.categoriesOptions}
                          onChange={this.selectCategory}
                          name="name"
                          value={this.state.categoryName}
                        />
                      </Form.Field>

                      {this.state.selectedCategory === true ? (
                        <div>
                          <Form.Field>
                            <label>Technical Skill</label>

                            <Dropdown
                              placeholder="Select..."
                              search
                              clearable
                              selection
                              multiple
                              options={this.props.technicalSkillsOptions}
                              onChange={this.onSelectFilterChange}
                              name="technicalSkill"
                            />
                          </Form.Field>
                        </div>
                      ) : null}
                    </Form.Group>
                  </Form>
                </Grid.Row>
                <Grid.Row>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>Internal Level</label>
                        <Dropdown
                          placeholder="Select..."
                          name="internalLevel"
                          defaultValue={this.state.filtersId.internalLevel}
                          multiple
                          search
                          clearable
                          selection
                          options={this.props.internalLevelsOptions}
                          onChange={this.onSelectFilterChange}
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>Office Location</label>
                        <Dropdown
                          placeholder="Select..."
                          name="office"
                          search
                          clearable
                          selection
                          options={this.props.officesOptions}
                          onChange={this.onSelectFilterChange}
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>Unit</label>
                        <Dropdown
                          placeholder="Select..."
                          name="unit"
                          multiple
                          search
                          clearable
                          selection
                          options={this.deleteDuplicate(this.props.hierarchyPracticeOptions)}
                          onChange={this.onSelectFilterChange}
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>Company</label>
                        <Dropdown
                          placeholder="Select..."
                          name="company"
                          multiple
                          search
                          clearable
                          selection
                          options={this.props.companiesOptions}
                          onChange={this.onSelectFilterChange}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Grid.Row>
                <Grid.Row>
                  <Form>
                    <Form.Group widths="equal">
                      {/*<Form.Field>*/}
                      {/*  <label>Subpractice</label>*/}
                      {/*  <Dropdown*/}
                      {/*    placeholder="Select..."*/}
                      {/*    name="subPractice"*/}
                      {/*    multiple*/}
                      {/*    search*/}
                      {/*    clearable*/}
                      {/*    selection*/}
                      {/*    options={this.deleteDuplicate(this.props.hierarchySubPracticeOptions)}*/}
                      {/*    onChange={this.onSelectFilterChange}*/}
                      {/*  />*/}
                      {/*</Form.Field>*/}

                      <Form.Field>
                        <label>Manager</label>
                        <Dropdown
                          placeholder="Select..."
                          name="manager"
                          multiple
                          search
                          clearable
                          selection
                          options={this.props.managerOptions}
                          onChange={this.onSelectFilterChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Customer</label>
                        <Dropdown
                          placeholder="Select..."
                          name="customer"
                          multiple
                          search
                          clearable
                          selection
                          options={this.props.customerOptions}
                          onChange={this.onSelectFilterChange}
                        
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>Language</label>
                        <Dropdown
                          placeholder="Select..."
                          name="language"
                          multiple
                          search
                          clearable
                          selection
                          options={this.props.languagesOptions}
                          onChange={this.onSelectFilterChange}
                        />
                      </Form.Field>

                      {/*<Form.Field>
                        <label>Start Date</label>
                        <DatePicker selected={this.state.filtersId.startDate.toDate()}
                         onChange={date=>this.onStartDateChange(date)}
                         dateFormat="MM/yyyy"
                         showMonthYearPicker
                         />
                      </Form.Field>

                      <Form.Field>
                        <label>End Date</label>
                        <DatePicker selected={this.state.filtersId.endDate.toDate()}
                         onChange={date=>this.onEndDateChange(date)}
                         dateFormat="MM/yyyy"
                         showMonthYearPicker
                         />
                      </Form.Field>*/}

                      {/*
  <Form.Field>
                          <label>Deputy Manager</label>                        
                          <Dropdown
                            placeholder='deputyManager'
                            name='deputyManager'                                                     
                            multiple
                            search
                            clearable
                            selection
                            options={this.props.deputyManagerOptions}
                            onChange={this.onSelectFilterChange}
                          />
                        </Form.Field>
*/}
                    </Form.Group>
                  </Form>
                </Grid.Row>
                <Grid.Row>
                  <Form>
                    <Form.Group widths="equal" />
                  </Form>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <IconButton
                    icon="search"
                    label="Search"
                    onClick={this.searchCV}
                    floated="right"
                    //disabled={this.checkSearchFields()}
                    loading={this.props.loading}
                  />
                </Grid.Row>
              </Grid>
            </div>
          </Segment>
        ) : null}

        <Segment clearing>
          <Header>{"Search Results"}</Header>
          <div
            style={{
              marginTop: "2rem",
              marginLeft: "2rem",
              marginRight: "2rem",
            }}
          >
            <PaginatedReusableTable
              columns={this.researchCols()}
              data={data ? data : []}
              loading={this.props.loading}
              pageSize={5}
              onClick={this.onSelectRow}
            //renderRowSubComponent={this.downloadDocsCv}
            />
          </div>
        </Segment>

        <Modal
          dimmer="blurring"
          open={this.state.openModalCV}
          onClose={this.closeModalCV}
          size="mini"
          closeIcon
        >
          <Modal.Header>Download CV</Modal.Header>
          <Modal.Actions>
            <Button onClick={this.downloadDocsCv}>Download</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default ResearchComponent;
