import React from "react";
import { connect } from "react-redux";
import { Dropdown, Header, Icon, Label, Image, Menu, Popup, Responsive } from "semantic-ui-react";
import ReusableSidebar from "../../components/shared/ReusableSidebar";
import { NavLink } from "react-router-dom";
import { startLogout } from "../../store/actions/shared/auth";
import { getTitleDecorator } from "../../utils/functionUtils";
import { Link } from 'react-router-dom';
require("moment/locale/en-gb");

class NavigationbarDesktop extends React.Component {
  constructor(props) {
    super(props);

    var now = new Date();
    var year = now.getFullYear();
    var classNamePersonalize = "";

    var dateAprilFool = new Date(year, 3, 1);
    var dateChristmasFrom = new Date(year, 11, 19);
    var dateChristmasTo = new Date(year, 11, 29);

    /*
        // I'm sorry :(
        if ((now === dateAprilFool) || (now >= dateChristmasFrom && now <= dateChristmasTo)){
          window.localStorage.setItem("TEP_menu", "navslide_open");
        }
        else{
          window.localStorage.setItem("TEP_menu", "navslide_closed");
        }
    */

    var menuState = window.localStorage.getItem("TEP_menu");

    if (menuState === "navslide_closed") {
      this.state = {
        isOpen: false,
        visible: "visibile",
        sideBarClasses: "very thin icon",
        marginLeft: "marginlefting",
        marginRight: "marginrighting",
        navfixed: "",
        mobileMenu: true,
        activeIndex: props.activeIndex,
        hover: false
      };
    } else {
      this.state = {
        isOpen: false,
        visible: "visibile",
        sideBarClasses: "",
        marginLeft: "",
        marginRight: "",
        navfixed: "navslide open",
        mobileMenu: false,
        activeIndex: props.activeIndex
      };
    }
  }

  toggleVisibility = () => {
    if (this.state.sideBarClasses !== "") {
      window.localStorage.setItem("TEP_menu", "navslide_open");
      this.setState({
        visible: "visibile",
        sideBarClasses: "",
        marginLeft: "",
        marginRight: "",
        navfixed: "navslide open",
        mobileMenu: false
      });
    } else {
      window.localStorage.setItem("TEP_menu", "navslide_closed");
      this.setState({
        visible: "visibile",
        sideBarClasses: "very thin icon ",
        marginLeft: "marginlefting",
        marginRight: "marginrighting",
        navfixed: "",
        mobileMenu: true
      });
    }
  };

  hoverOn = () => {
    this.setState({ hover: true });
  };
  hoverOff = () => {
    this.setState({ hover: false });
  };

  render() {
    const {
      visible,
      sideBarClasses,
      marginLeft,
      navfixed,
      mobileMenu,
    } = this.state;

    let username = "";
    let avatar = "";
    if (this.props.auth && this.props.auth.user) {
      username = this.props.auth.user.attributes.name;
      avatar = this.props.auth.user.attributes.picture;
    }

    return (
      <Responsive minWidth={550}>
        <ReusableSidebar
          visible={visible}
          classes={sideBarClasses}
          showMobileMenu={mobileMenu}
          activeIndex={this.state.activeIndex}
          logoSidebar={true}
          loading={this.props.loading}
        />
        <div className="pusher pushable">
          <div className="pusher">
            <div className={"nav-bar navwrap"}>
              <Menu
                style={{ borderRadius: 0 }}
                className={
                  "navslide inverted " +
                  marginLeft
                }>
                <div
                  id="nav-icon1"
                  className={"item " + navfixed}
                  style={{ cursor: "pointer" }}
                  onClick={this.toggleVisibility}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="item ">
                  <NavLink to="/">
                    <Header
                      as="h2"
                      inverted
                      style={{
                        color: "white",
                        fontSize: "1.5em",
                        fontWeight: "700",
                      }}
                    >
                      Avvale Enterprise Portal {getTitleDecorator(window.location.href)}

                    </Header>
                  </NavLink>
                </div>
                <Menu.Menu position='right'>
                  <Link to={"/avvale-support"} target="_blank" style={{ textAlign: "right", padding: "12px" }} >
                    <Popup
                      content={"Need help? Click here."}
                      trigger={(
                        <Label style={{ marginBottom: "0" }} color="teal" circular>
                          HELP
                        </Label>
                      )}
                    />
                  </Link>

                  <Dropdown item
                    trigger={(<span>{username}<Image style={{ marginLeft: "5px" }} avatar src={avatar} alt='profile picture' /></span>)}
                    pointing='top right'
                    icon={null}>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.props.logout}><Icon name="sign out" />Sign out</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Menu>
              </Menu>
            </div>

            <div className={"body mainWrap navslide " + marginLeft}>
              {/*<PopupMessage/> */}
              {this.props.children}
            </div>


          </div>
        </div>

        {/*da mettere a posto bug di scroll e apertura menu*/}

        {/* {this.state.sideBarClasses === "" ?
          <div className="christmasTree" onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
            <div className="containerTree">
              <div className="star"></div>
              <div className="pressie">
                <div className="cover"></div>
                <div className="wrap"> </div>
                <div className="ribbon"></div>
              </div>
              <div className="tree">
                <div className="base"> </div>
                <div className="layer two">
                  <div className="line"> </div>
                  <div className={this.state.hover ? "bauble one light" : "bauble one"}></div>
                </div>

                <div className="layer three">
                  <div className="line three"> </div>
                  <div className={this.state.hover ? "bauble three light" : "bauble three"}></div>
                  <div className="socks two">
                    <div className="top"> </div>
                    <div className="foot two"></div>
                  </div>
                </div>
              </div>

              <div className="layer four">
                <div className={this.state.hover ? "bauble four light" : "bauble four"}></div>
                <div className="star two"></div>
                <div className="line four"> </div>
              </div>
            </div>
          </div>
          : <div></div>
        } */}
      </Responsive>
    )
      ;
  }
}

const mapStateToProps = (state, props) => {
  return { auth: state.authReducer };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(startLogout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationbarDesktop);
