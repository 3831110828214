import _ from 'lodash';
import matchSorter from 'match-sorter';
import * as React from "react";
import { connect } from "react-redux";
import { Button, Container, Dimmer, Dropdown, Form, Grid, Label, Loader, Modal, Popup, Radio, Search, Segment } from "semantic-ui-react";
import Can from "../../../abilities/Can";
import PaginatedReusableTable from '../../../components/shared/PaginatedReusableTable';
import ReusableHeaderWithHelp from '../../../components/shared/ReusableHeaderWithHelp';
import { history } from "../../../routers/AppRouters";
import { startGetProjectTypes } from '../../../store/actions/core/administration/project_type';
import { resetSearchBudget, startGetBudgets } from "../../../store/actions/core/project-planning/budget";
import { amISuperAdmin } from '../../../utils/functionUtils';


// TEP-64: edit budget search by project code and project name
const searchOptions = [
  { value: "project", label: "Project code & description" },
  { value: "project_type", label: "Project Type"},
  { value: "billing_client", label: "Billing client"},
  { value: "project_manager", label: "Project manager" }
]


const ProjectStatuses = [
  { value: "all", text: "All" },
  { value: "active", text: "Active" },
  { value: "inactive", text: "Inactive" },
  { value: "pending", text: "Pending" },
  { value: "rejected", text: "Rejected" },
];

const BudgetCols = [
  {
    Header: 'Project Code',
    accessor: 'project_code',
    id: 'project_code',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Description',
    accessor: 'description',
    id: 'description',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Project Type',
    accessor: 'project_type',
    id: 'project_type',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Project Status',
    accessor: 'project_status',
    id: 'project_status',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    id: 'start_date',
    filterAll: true,
    width: 88,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    id: 'end_date',
    filterAll: true,
    width: 88,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },

  // AEP-75: Creation date column in PFE/Budget page
  {
    Header: 'Creation Date',
    accessor: 'creation_date',
    id: 'creation_date',
    filterAll: true,
    width: 88,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
    }),
  },

  {
    Header: 'Billing Client',
    accessor: 'billing_client',
    id: 'billing_client',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Status Type',
    accessor: 'status_type',
    id: 'status_type',
    width: 86,
    Cell: row => {
      switch (row.value) {
        case "t0":
          return "Planning (T0)";
        case "t1":
          return "Planning Review (T1)";
        case "t2":
          return "Actual & Forecast (T2)";
        default:
          return "";
      }
    },
    filterMethod: (filter, row) => {
      if (filter.value === 'all') {
        return true;
      } else {
        console.log("FILTER:", row.status, filter.value);
        return row.status_type === filter.value;
      }
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Show All</option>
        <option value="t0">Planning (T0)</option>
        <option value="t1">Planning Review (T1)</option>
        <option value="t2">Actual & Forecast (T2)</option>
      </select>
    )
  },
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    Cell: row => {
      switch (row.value) {
        case "created":
          return "Created";
        case "cutoff":
          return "Cutoff";
        case "actual":
            return "Actual";
        case "pending":
          return "Pending";
        case "actual pending":
          return "Actual Pending";
        case "change requested":
          return "Change Requested";
        case "cr pending":
          return "CR Pending";
        case "confirmed":
          return "Confirmed";
        case "saved":
          return "Saved";
        default:
          return "";
      }
    },
    filterMethod: (filter, row) => {
      if (filter.value === 'all') {
        return true;
      } else {
        console.log("FILTER:", row.status, filter.value);
        return row.status === filter.value;
      }
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Show All</option>
        <option value="created">Created</option>
        <option value="cutoff">Cutoff</option>
        <option value="actual">Actual</option>
        <option value="actual pending">Actual Pending</option>
        <option value="change requested">Change Requested</option>
        <option value="cr pending">CR Pending</option>
        <option value="confirmed">Confirmed</option>
        <option value="saved">Saved</option>
      </select>
    )
  },
  {
    Header: 'Currency',
    accessor: 'currency_code',
    id: 'currency_code',
    filterAll: true,
    width: 61,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    Header: 'Gross Margin',
    accessor: 'gross_margin',
    id: 'gross_margin',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  }
];

class BudgetContainer extends React.Component {

  constructor(props) {
    super(props);

    let filters = {};
    filters["start"] = 0;
    filters["size"] = 20;
    filters["projectStatus"] = "active";

    this.state = {
      values: {},
      //search filters
      filters: filters,
      //PaginatedTable state
      page: 0,
      pageSize: 20,
      selectedRow: {},
      user_groups: [],
      searchValue: "",
      searchOption: "project"
    };
  }

  componentWillMount() {
    if (history.location.pathname === '/project-planning/budget') {
      this.props.getProjectTypes();
      var search = this.state.filters;
      search['type'] = this.state.searchOption;
      this.props.getBudgets(this.state.filters);
    }else{
      if(this.props.filterParam != undefined){
        let filters=[];
        filters["start"] = 0;
        filters["size"] = 20;
        filters["projectStatus"] = this.props.filterParam.projectStatus;
        this.setState(prevState => ({ ...prevState,
          searchValue: isNaN(this.props.filterParam.searchValue) ? this.props.filterParam.searchValue : '',
          searchOption: (this.props.filterParam.searchOption === 'budget_id') ? 'project' : this.props.filterParam.searchOption,
          filters: filters,
        }));
      }
    }
  }

  componentWillReceivePropscomponentWillReceiveProps(nextProps) {
    let user_groups = {};
    if (this.props.user)
      if (this.props.user.signInUserSession)
        if (this.props.user.signInUserSession.idToken)
          if (this.props.user.signInUserSession.idToken.payload) {
            user_groups = JSON.parse(this.props.user.signInUserSession.idToken.payload.user_groups);
          }

    this.setState({
      user_groups: user_groups
    });
  }

  componentDidUpdate() {
    let user_groups = {};
    if (user_groups === null) {
      user_groups = JSON.parse(this.props.user.signInUserSession.idToken.payload.user_groups);

      this.setState(prevState => ({
        ...prevState,
        user_groups: user_groups
      }));
    }
  }

  onPageChange = (pageIndex) => {
    //console.log("PAGE INDEX", pageIndex);
    this.setState({ page: pageIndex });
    // TODO fetch new data if necessary
    let filters = this.state.filters;
    filters['start'] = pageIndex * this.state.pageSize;
    filters['size'] = this.state.pageSize;
    filters['type'] = this.state.searchOption;

    this.props.getBudgets(filters); // fetch filtered reports
  };

  onSelectRow = selectedRow => {
    //console.log(selectedRow);
    if (selectedRow.status === "pending") {
      return;
    } else {
      this.setState(prevState => ({
        ...prevState,
        selectedRow: selectedRow
      }));
      history.push({
        pathname: "/project-planning/budget/" + selectedRow.id, state: selectedRow
      })
    }
  }

  handleSearchDropdown = (event, data) => {
    var project_code = data.options.find(o => o.value === data.value).code;
    this.setState(prevState => ({
      ...prevState,
      searchValue: project_code
    }));

    var params = this.state.filters;
    params["type"] = this.state.searchOption;
    params["query"] = project_code;
    this.props.getBudgets(params);
  }


  handleChange = (e, { value }) => {
    this.setState({
      searchValue: value,
      filters: {
        ...this.state.filters,
        query: value
      }
    });
    var params = this.state.filters;
    if (value.length >= 2) {
      params["query"] = value;
      params["type"] = this.state.searchOption;
      this.props.getBudgets(params);
    } else {
      this.props.resetSearch();
    }
  };

  onSelectChange = (e, data) => {
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [data.name]: data.value
      }
    }));

    var params = this.state.filters;
    params[data.name] = data.value;
    if (data.value.length >= 2){
      params["query"] = this.state.searchValue;
      params["type"] = this.state.searchOption;
      this.props.getBudgets(params);
    } else {
      //console.log("LENGTH: ", this.state.searchValue.length);
      this.props.resetSearch();
    }
  };

  onCloseSearchModal = () => {
    this.setState(prevState => ({
      ...prevState,
      openSearchModal: false
    }));
  }

  handleChangeRadio = (e, { value }) => {
    this.setState(prevState => ({
      ...prevState,
      searchOption: value,
      searchValue: ""
    }));
  }

  render() {

    const data = this.props.budgets ? this.props.budgets.data : [];
    const meta = this.props.budgets ? this.props.budgets.meta : undefined;

    let pages = -1;
    if (meta) {
      pages = Math.ceil(meta.total_element / meta.size);
    }

    return (
      <Container>
        <ReusableHeaderWithHelp title="Budgets" link="/help/project-planning/budget" />

        <Modal
            size="mini"
            open={this.state.openSearchModal}
            closeOnEscape={false}
            closeOnDimmerClick={true}
            onClose={this.onCloseSearchModal}
            closeIcon
            dimmer="blurring"
          >

            <Modal.Header>Select Search Option</Modal.Header>
            <Modal.Content>
            <Form size="massive">
                {searchOptions.map((field, i) => {
                  if (field.value === 'project_manager' && !amISuperAdmin(this.props.user_groups) ){
                    return null;
                  }
                  return (
                    <Form.Field key={i}>
                      <Radio
                        label={field.label}
                        value={field.value}
                        name='radioGroup'
                        checked={this.state.searchOption === field.value}
                        onChange={this.handleChangeRadio}
                      />
                    </Form.Field>
                  );
                  })}
                </Form>
            </Modal.Content>
            <Modal.Actions>

              <Button
                onClick={this.onCloseSearchModal}
                content='Select'
              />
            </Modal.Actions>
          </Modal>


        <Dimmer active={this.props.loadingBudgets} inverted>
          <Loader indeterminate inverted content="Loading" size="medium" />
        </Dimmer>
        <Can I='project_planning:Read' a='budgets'>
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Search Budget by {((searchOptions.filter(x => x.value == this.state.searchOption))[0] !== undefined) ? (searchOptions.filter(x => x.value == this.state.searchOption))[0].label : 'Project code'}</Label>
                  <Popup
                    content={"Type budget digits to start searching"}
                    trigger={(
                      <Label color="teal" circular>
                        ?
                      </Label>
                    )}
                  />
                  {this.state.searchOption !== "project_type" ?
                    <Search
                      name="budget"
                      input={{ fluid: true }}
                      onSearchChange={this.handleChange}
                      placeholder={"Search all budgets by " + (searchOptions.filter(x => x.value == this.state.searchOption))[0].label + "..."}
                      showNoResults={false}
                      size="large"
                      minCharacters={3}
                      loading={this.props.loading}
                      value={this.state.searchValue}
                    />
                    :
                    <Dropdown
                      name="dropdown_type"
                      placeholder='Choose a Project Type'
                      fluid
                      selection
                      options={this.props.options.project_type}
                      onChange={this.handleSearchDropdown}
                    />
                  }
                  <Button
                        floated="right"
                        basic
                        style={{ 'margin': '0px 10px 0px 5px' }}
                        content={"Search Option: " + (((searchOptions.filter(x => x.value == this.state.searchOption))[0] !== undefined) ? ((searchOptions.filter(x => x.value == this.state.searchOption))[0].label).toUpperCase() : 'PROJECT CODE')}
                        onClick={() => this.setState({ openSearchModal: true })}
                        color='teal'
                        icon='filter'
                      />
                </Grid.Column>
                <Grid.Column width={2}>
                <Form.Input type="hidden" />
                    <label>Project Status</label>
                    <Form.Select
                      placeholder="Filter by..."
                      name="projectStatus"
                      options={this.props.options.projectStatuses}
                      onChange={this.onSelectChange}
                      value={this.state.filters.projectStatus}
                    />
                    </Grid.Column>
                <Grid.Column width={6}>
                  <Popup content="Refresh page with the same search parameter" trigger={<Button
                    content="Refresh" floated='right' icon='refresh'
                    onClick={() => this.handleChange(null, {value: this.state.searchValue})} />} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                <div style={{ marginTop: '50px' }}>
                  <PaginatedReusableTable
                    manual={pages > 1 ? true : false}
                    filterable={pages > 1 ? false : true}
                    sortable={pages > 1 ? false : true}
                    loading={this.props.loadingBudgets}
                    columns={BudgetCols}
                    data={data}
                    onClick={this.onSelectRow}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pages={pages}
                    pageSize={this.state.pageSize}
                  />
                </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Can>
      </Container>

    );
  }
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  user_groups: state.authReducer.user_groups,
  loadingBudgets: state.budgetReducer.loading,
  budgets: state.budgetReducer.budgets,
  filterParam: state.budgetReducer.filterParam,
  options: {
    projectStatuses: ProjectStatuses,
    project_type: _.toArray(
      _.mapValues(_.filter(state.projectTypeReducer.projecttypes, { 'active': 1, 'corporate': 0 }), o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name,
          code: o.code
        };
      })
    ),
  }
});

const mapDispatchToProps = dispatch => {
  return {
    getBudgets: (params) => dispatch(startGetBudgets(params, false)),
    resetSearch: () => dispatch(resetSearchBudget()),
    getProjectTypes: () => dispatch(startGetProjectTypes()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetContainer);
