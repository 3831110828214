import React, { Component } from "react";
import { connect } from "react-redux";
import { Breadcrumb, Button, Checkbox, Container, Header, Icon, Segment, Dimmer, Loader, Modal } from "semantic-ui-react";
import { history } from "../../../routers/AppRouters";
import Can from "../../../abilities/Can";
import { NavLink } from "react-router-dom";
import matchSorter from "match-sorter";
import ReusableTable from "../../../components/shared/ReusableTable";
import IconButton from "../../../components/shared/buttons/IconButton";
import _ from "lodash";
import { startGetCompanies } from "../../../store/actions/shared/company";
import {
  startCreateCorporateProject,
  startRemoveConsultantFromCorporate,
  startGetCorporateProjects,
  startToggleTimeReporting,
  startGetCorporateProject,
  startGetProjectConsultants,
  startCreateConsultantToCorporate,
  startEditCorporateProject,
  startGetGDPRForm,
  startSubmitGDPRForm,
  startGetGDPRAnswers,
  startUpdateGDPRAnswers,
  startGetRiskEvalAssets, startGetRiskEvalAssetsDetails,
  startGetRiskEvalSurvay, startSubmitRiskEvalForm,
  startSubmitRiskEvalSurvey
} from "../../../store/actions/core/project/project";
import {
  resetSearch,
  startSearchAllConsultant
} from "../../../store/actions/core/administration/manage_consultant";
import { startGetProjectTypes } from "../../../store/actions/core/administration/project_type";
import NewCorporateProjectFormModal from "../../../components/core/project/NewCorporateProjectFormModal";
import { AddConsultantModal } from "../../../components/core/project/AddConsultantModal";
import ProjectForm from "../../../components/core/project/ProjectForm";
import projectFormData from "../../../assets/resources/corporate_project_read";
import projectEditFormData from "../../../assets/resources/corporate_project_edit";
import { toast } from "react-semantic-toasts";
import constants from "../../../utils/constants";
import { amISuperAdmin } from '../../../utils/functionUtils';
import  GDPRForm  from "../../../components/core/project/GDPRForm";
import RiskAssessmentForm from "../../../components/core/project/RiskAssessmentForm";


class CorporateProjectsContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showFormModal: false,
      loading: false,
      values: {},
      resultant_code: "",
      details_corp: this.props.match.params.project_id ? true : false,
      selectedConsultant: {},
      consultantSearch: "",
      disable: false,
      editable: false,

      warningProjectCorporate: false,

      GDPRFormOpen: false,
      editRiskEvaluationForm: false,
      alreadySetted: false,
    };

  }

  componentWillMount() {
    this.setState(prevState => ({
      ...prevState,
      loading: this.props.loading,
      details_corp: this.props.match.params.project_id ? true : false
    }));

    this.props.getProjectTypes();
    this.props.getCorporateProjects();
    this.props.getCompanies();
    if (this.state.details_corp) {
      this.props.getProjectDetails(this.props.match.params.project_id);
      this.props.getConsultantToCorporate(this.props.match.params.project_id);
      this.props.GetGDPRAnswers(this.props.match.params.project_id);
      this.props.getGDPRForm(this.props.match.params.project_id);
      this.props.getRiskEvalAsset(this.props.match.params.project_id);
    }
  }

  componentWillReceiveProps(props) {
    if(props.project !== undefined && props.project !== this.props.project){
      if(props.project.risk_flag === 1){
        props.project.risk_flag = 'Completed'
      }else{
        props.project.risk_flag = 'Not Completed'
      }
    }

    if(props.gdpr_answers!== undefined && props.gdpr_answers !== this.props.gdpr_answers && !this.state.alreadySetted){
      if(props.project.gdpr_flag === 1){
        if(props.gdpr_answers.length > 0){
          props.project.gdpr_flag = 'Completed'
          this.setState({alreadySetted: true})
        }else{
          props.project.gdpr_flag = 'Not Requested'
          this.setState({alreadySetted: true})
        }
      }else{
        props.project.gdpr_flag = 'Not Completed'
      }
    }
  }

  handleToggleTimeReporting = (projectId, status) => {
    //console.log("Toggling TR for proj", projectId, status);
    this.props.toggleTimeReporting(projectId, status);
  };

  handleCorporateToggleTimeReporting = (e, { checked }) => {
    const projectId = this.props.match.params.project_id;
    const status = checked;

    //console.log("Toggling TR for proj", projectId, status);
    this.props.toggleTimeReporting(projectId, status);

  };

  getColumns = () => {
    if (!this.state.details_corp) {
      return [
        {
          Header: "Code",
          accessor: "code",
          id: "code",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Description",
          accessor: "name",
          id: "name",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Company",
          accessor: "company_name",
          Cell: row => row.value ? row.value : "All Companies",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Project Type",
          accessor: "project_type",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Timereporting",
          accessor: "tr_enabled",
          Cell: row => <Can I="projects:Update" a="my-projects"><Checkbox checked={row.value === 1} toggle onChange={(e, { checked }) => {
            e.stopPropagation();
            this.handleToggleTimeReporting(row.original.id, checked);
          }} /></Can>,
          filterMethod: (filter, row) => {
            //console.log("FILTER:", row, row.tr_enabled, filter.value);
            if (filter.value === "all") {
              return true;
            } else return row.tr_enabled.toString() === filter.value;
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          )
        }
      ];
    } else {
      return [
        {
          Header: "CN",
          accessor: "cn_code",
          id: "code",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Name",
          accessor: "name",
          id: "name",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Surname",
          accessor: "surname",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Delete",
          accessor: "id",
          id: "id",
          Cell: (row) => (
            <Can I="projects:Create" a="consultants">
            <div onClick={this.handleDelete}>
              <Button icon id={row.value}>
                <Icon name='trash' id={row.value} />
              </Button>
            </div>
            </Can>
          ),
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        }
      ];
    }

  };

  handleSubmit = () => {
    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Error",
          description: "The corporate project hasn't been created. Try again.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      this.closeModal();

    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {

          title: "Success",
          description: "The corporate project has been created.",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    const values = this.state.values;
    values.code = values.proj_type + values.initial_code;
    //console.log("Insert new corporate project with ", values);

    delete (values.company);
    delete (values.initial_code);
    delete (values.proj_type);
    delete (values.project_type);
    this.props.createCorporateProject(values, onSuccess, onFailure);
  };

  handleChange = (e, data) => {
    //console.log(data, data.name);
    let values = {};

    if (data.options) {
      let data_id = data.options.find(o => o.value === data.value).key;
      let name_id = data.name + "_id";
      values = {
        ...this.state.values,
        [name_id]: data_id,
        [data.name]: data.value
      };
    } else if (data.type === "checkbox") {
      values = {
        ...this.state.values,
        [data.name]: data.checked
      };
    } else {
      values = {
        ...this.state.values,
        [data.name]: data.value
      };
    }

    //Create code for a new corporate project
    if (data.name === "project_type") {
      var proj_type = data.options.find(o => o.value === data.value).type;
      var str = "";
      for (var i = 0; i < proj_type.length; i++) {
        str += proj_type.charAt(i) + "_";
      }
      values.proj_type = str;

      this.setState(prevState => ({ ...prevState, values: values }));
    } else {
      this.setState(prevState => ({ ...prevState, values: values }));
    }

    //console.log("values: ", this.state.values);
  };

  closeModal = () => this.setState({
    showFormModal: false,
    values: {},
    resultant_code: ""
  });

  handleSearchChange = (e, data) => {
    //console.log("Search consultant", this.state.consultantSearch);
    //console.log("[handleSearchChange] ", data.value);
    this.setState(prevState => (
      {
        ...prevState,
        consultantSearch: data.value
      }
    ));

    if (this.state.consultantSearch.length >= 2) {
      this.props.searchConsultants({ query: data.value });
    } else {
      //console.log("LENGTH: ", this.state.consultantSearch.length);
      this.props.resetSearch();
    }
  };

  handleRemoveConsultantModalOpen = (row) => {
    //console.log("want to remove", row);
    this.setState({
      removeConsultantModalOpen: true,
      selectedConsultant: row
    });
  };

  handleClose = () => this.setState({
    showFormModal: false,
    removeConsultantModalOpen: false,
    selectedConsultant: {}
  });

  handleRemoveConsultant = (row) => {
    const consultantId = row.id;

    //console.log("Removing ", consultantId, "from project", this.state.project_id);

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Project Corporate Consultants",
          description: "Consultant correctly removed from project corporate.",
          type: "success",
          icon: "user",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
    };

    const onFailure = res => {
      //console.log("onFailure", res);
      toast(
        {
          title: "Sales level Consultants",
          description: "Error removing consultant from project corporate.",
          type: "error",
          icon: "exclamation",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    };
    this.props.removeConsultantFromProject(this.props.match.params.project_id, consultantId, onSuccess, onFailure);
    this.handleClose();
  };

  handleChangeConsultant = (e, data) => {
    //console.log("handleChangeConsultant: ", data.result);
    this.setState(prevState => (
      {
        ...prevState,
        consultantSearch: data.result.title,
        selectedConsultant: {
          ...prevState.selectedConsultant,
          user: data.result.value
        }
      }
    ));

  };

  addConsultant = () => {
    const onFailure = res => {
      //console.log("onError", res);
      let description = "";
      if (res === 409) {
        description = "Consultant is already present in sales level."
      } else if (res === 412) {
        description = "You are not authorized to take this action.";
      } else {
        description = "Error adding consultant to sales level.";
      }
      toast(
        {
          title: "Error",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT
        });
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Success",
          description: "Consultant correctly added to sales level.",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT
        }
      );
    };

    const projectId = this.props.match.params.project_id;
    const params = this.state.selectedConsultant;

    //invoke actions that adds selectedConsultant to currently selected project
    //console.log("Adding ", params, "to project", projectId);
    this.props.addConsultantToCoporate(projectId, params, onSuccess, onFailure);
    this.handleClose();
  };

  handleDelete = (e) => {
    e.stopPropagation();
    const targetID = e.target.getAttribute('id');
    let selectedRow = this.props.consultants.find(o => o.id === Number(targetID));
    //console.log(targetID, " ", selectedRow);

    this.setState({
      selectedConsultant: selectedRow,
      removeConsultantModalOpen: true
    });
  }

  handleRowClick = clickedRow => {
    this.setState(prevState => ({
      ...prevState,
      details_corp: true
    }
    ));

    //console.log(this.state.details_corp);
    history.push("/project/corporate/" + clickedRow.id);

    this.props.getProjectDetails(clickedRow.id);
    this.props.getConsultantToCorporate(clickedRow.id);
  };

  editProject = () => {
    const { project } = this.props;
    //console.log("project options: ", project);
    //console.log("options: ", options);

    this.setState({
      editable: true,
      values: {
        description: project.name,
        always_available: project.always_available ? true : false
      }
    });
  };

  handleEditSubmit = () => {
    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Error",
          description: "The corporate project hasn't been modified. Try again.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      this.closeModal();

    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {

          title: "Success",
          description: "The corporate project has been modified.",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };
    //console.log("[handleEditSubmit]", this.state.values);

    this.props.editProject(this.props.match.params.project_id, JSON.parse(JSON.stringify(this.state.values)), onSuccess, onFailure);

    this.setState({
      editable: false,
      values: undefined
    });
  };

  openGDPRForm = () => {
    if(this.props.project.gdpr_flag === 'Completed' || this.props.project.gdpr_flag==='Not Requested'){
      this.props.project.gdpr_flag = 1
    }else{
      this.props.project.gdpr_flag = 0
    }
    this.setState({
      GDPRFormOpen: true
    });
  }

  openRiskForm = () => {
    if(this.props.project.risk_flag === 'Completed'){
      this.props.project.risk_flag = 1
    }else{
      this.props.project.risk_flag = 0
    }
    this.setState({editRiskEvaluationForm: true})
  }

  handleGDPRClose = () => {
    if(this.props.gdpr_answers !== undefined){
      if(this.props.project.gdpr_flag === 1){
        if(this.props.gdpr_answers.length > 0){
          this.props.project.gdpr_flag = 'Completed'
          this.setState({alreadySetted: false})
        }else{
          this.props.project.gdpr_flag = 'Not Requested'
          this.setState({alreadySetted: false})
        }
      }else{
        this.props.project.gdpr_flag = 'Not Completed'
      }
    }
    this.setState({
      GDPRFormOpen: false
    })
  }

  handleCloseRisk = () => {
    if(this.props.project.risk_flag === 1){
      this.props.project.risk_flag = 'Completed'
    }else{
      this.props.project.risk_flag = 'Not Completed'
    }
    this.setState({editRiskEvaluationForm: false})
  }

  submitGDPR = (answers) => {
    if(this.props.project.gdpr_flag)
      this.props.updateGDPRAnswers(this.props.match.params.project_id, answers, true)
    else
      this.props.submitGDPR(this.props.match.params.project_id, answers, true);
  }

  onCancel = () => {
    if(this.props.project.risk_flag === 1){
      this.props.project.risk_flag = 'Completed'
    }else{
      this.props.project.risk_flag = 'Not Completed'
    }
    this.setState({editRiskEvaluationForm: false})
    this.props.getRiskEvalAsset(this.props.match.params.project_id);
  }

  submitRisk = () => {
    if(this.props.project.risk_flag === 1){
      this.props.project.risk_flag = 'Completed'
    }else{
      this.props.project.risk_flag = 'Not Completed'
    }
    this.state.alreadySetted = false
    this.setState({alreadySetted: false})
    this.setState({editRiskEvaluationForm: false})
    this.props.GetGDPRAnswers(this.props.match.params.project_id);
    this.props.getRiskEvalAsset(this.props.match.params.project_id);
  }

  render() {
    const { editable } = this.state;
    const { project } = this.props;

    if (amISuperAdmin(this.props.user_groups) && this.state.warningProjectCorporate === false){
      alert("Warning: this section should be seen by Project Managers and HR Department.");
      this.setState((prevState) => ({
          ...prevState,
          warningProjectCorporate: true
      }));
  }

    return (
      <Container>

        <Can I="projects:Read" a="corporate_projects">
          {() => (
            <React.Fragment>
              {this.state.details_corp ?
                <Dimmer active={Boolean(this.props.loading || this.props.loading_consultant_corp)} inverted>
                  <Loader inverted content="Loading" />
                </Dimmer>
                :
                <Dimmer active={this.props.loading_project_corp} inverted>
                  <Loader inverted content="Loading" />
                </Dimmer>
              }
              {!this.state.details_corp &&
                <Breadcrumb>
                  <Breadcrumb.Section as={NavLink} to={"/project"}>Projects</Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section>Corporate</Breadcrumb.Section>
                </Breadcrumb>
              }
              {this.state.details_corp &&
                <Breadcrumb>
                  <Breadcrumb.Section as={NavLink} to={"/project"}>Projects</Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section as={NavLink} to={"/project/corporate"}>Corporate</Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section>{this.props.project.code}</Breadcrumb.Section>
                </Breadcrumb>
              }

              <Segment>
                <React.Fragment>
                  {!this.state.editable && !this.state.editRiskEvaluationForm && !this.state.GDPRFormOpen &&
                    <div>
                      {
                        this.state.details_corp ?

                          <div>
                            <Button icon className="cancel" floated="left" onClick={() => history.goBack()}><Icon
                              name="arrow left" /></Button>
                            <Can I="projects:Update" a="corporate_projects">
                              {() => (
                                <IconButton label="Edit"
                                  floated="right"
                                  icon="edit"
                                  onClick={this.editProject} />
                              )}
                            </Can>
                            {!this.props.project.always_available &&

                              <Can I="projects:Create" a="consultants">
                              <IconButton label="Add Consultant" icon="plus" floated="right"
                                onClick={() => this.setState({ showFormModal: true })} />
                              </Can>

                            }
                            { project.has_risk_assessment === 1 &&
                              <Can I="projects:Update" a="risk-assessment">
                                {() => (
                                  <IconButton label="Additional project information for risk evalution"
                                    floated='right'
                                    icon="edit"
                                    onClick={this.openRiskForm}
                                  />
                                )}
                              </Can>
                            }
                            {this.props.gdpr_form && this.props.gdpr_answers && project.has_gdpr == 1 &&
                              <Can I="projects:Update" a="gdpr">
                                {() => (
                                  <IconButton label="GDPR form"
                                    floated='right'
                                    icon="edit"
                                    onClick={this.openGDPRForm} />
                                )}
                              </Can>}
                          </div>
                          :
                          <div>
                            <Button icon className="cancel" onClick={() => history.goBack()}><Icon
                              name="arrow left" /></Button>
                            <div>
                              <Header floated="left"> Corporate Projects</Header>
                              <Can I="projects:Create" a="corporate_projects">
                              <IconButton label="New Project" icon="plus" floated="right"
                                onClick={() => this.setState({ showFormModal: true, values: { "always_available": false } })} />
                              </Can>
                            </div>
                          </div>
                      }
                    </div>
                  }
                </React.Fragment>

                {!this.state.details_corp ?
                  <div style={{ marginTop: "50px" }}>
                    {this.props.projects &&
                      <ReusableTable
                        columns={this.getColumns()}
                        data={this.props.projects}
                        onClick={this.handleRowClick}
                        defaultPageSize={10}
                        setLoadingState={this.setLoadingState}
                        loading={this.props.loading_project_corp}
                      />
                    }

                    {this.state.showFormModal &&
                      <NewCorporateProjectFormModal
                        values={this.state.values}
                        handleChange={this.handleChange}
                        onClose={this.closeModal}
                        onSubmit={this.handleSubmit}
                        loadings={this.props.loadings}
                        options={this.props.options}
                      />
                    }
                  </div> :<>
                  { this.state.editRiskEvaluationForm || this.state.GDPRFormOpen ?
                      <>
                        {
                          this.state.GDPRFormOpen ?
                            <Can I="projects:Read" a="corporate_projects">
                              <GDPRForm handleClose={this.handleGDPRClose}
                                gdpr_form={this.props.gdpr_form}
                                gdpr_compiled={this.props.project.gdpr_flag}
                                previous_answers={this.props.gdpr_answers}
                                submitGDPR={this.submitGDPR}
                                loading={this.props.loading_GDPR_form}
                                final_customer={this.props.project.final_customer_name}
                              />
                            </Can>
                            :
                            <Can I="projects:Read" a="corporate_projects">
                              <RiskAssessmentForm
                                onCancel={this.onCancel}
                                goBack={this.handleCloseRisk}
                                onSubmit={this.submitRisk}
                                optionsAsset={this.props.risk_eval_assets}
                                getAssetsDetails={(assets_code) => this.props.getRiskEvalAssetsDetails(this.props.match.params.project_id, assets_code)}
                                assetsDetails={this.props.risk_eval_assets_details}
                                getSurvey={(main_asset_code) => this.props.getRiskEvalSurvey(this.props.match.params.project_id, main_asset_code)}
                                survey={this.props.risk_eval_survey}
                                submitRiskEvalSurvey={(answers) => this.props.submitRiskEvalSurvey(this.props.match.params.project_id, answers, true)}
                                submitRiskEvalForm={(vulnerabilities_codes) => this.props.submitRiskEvalForm(this.props.match.params.project_id, vulnerabilities_codes, true)}
                                loading={this.props.loading_risk_eval_form}
                              />
                            </Can>
                        }
                        <div style={{height: '50px'}}></div>
                      </> : <div className="project_details">
                    <ProjectForm
                      formFields={editable ? projectEditFormData : projectFormData}
                      values={editable ? this.state.values : this.props.project}
                      loadings={this.props.loading_project_corp}
                      options={this.props.options}
                      handleChange={this.handleChange}
                      editable={editable}
                      onSubmit={() => console.log("SUBMIT")}
                    />

                    {this.state.editable &&
                      <div style={{ marginTop: "1.5rem" }}>
                        <Button className="cancel"
                          onClick={() => this.setState({ editable: false })}>
                          Cancel
                        </Button>

                        <IconButton
                          floated='right'
                          icon='save'
                          label="Submit"
                          disabled={false} // TODO should buttons go inside Reusable form?
                          onClick={this.handleEditSubmit}
                        />
                      </div>
                    }

                    {!this.state.editable &&
                      <Can I="projects:Update" a="my-projects">
                        <Checkbox toggle
                          checked={this.props.project.tr_enabled === 1}
                          label='Time Reporting'
                          onChange={this.handleCorporateToggleTimeReporting}
                          loading={this.props.loading_timereport}
                        />
                      </Can>
                    }

                    {!this.state.editable && this.props.consultants &&
                      <div className={"table-no-row-click"} style={{ marginTop: "3rem" }}>
                        <Header as='h3'>Consultant for project</Header>

                        <ReusableTable
                          columns={this.getColumns()}
                          data={this.props.consultants}
                          onClick={() => this.setState({ disable: true })}
                          defaultPageSize={10}
                          setLoadingState={this.setLoadingState}
                          loading={this.props.loading_consultant_corp}
                        />
                      </div>
                    }

                    {this.state.showFormModal &&
                      <AddConsultantModal
                        open={this.state.showFormModal}
                        handleClose={this.handleClose}
                        consultants={this.props.consultant_options}
                        loading={this.props.loading_consultant}
                        selectedConsultant={this.state.selectedConsultant}
                        onChangeConsultant={this.handleChangeConsultant}
                        handleSearchChange={this.handleSearchChange}
                        addConsultant={this.addConsultant}
                        searchValue={this.state.consultantSearch}

                      />
                    }
                    {this.state.removeConsultantModalOpen &&

                      <Modal
                        dimmer='inverted'
                        open={this.state.removeConsultantModalOpen}
                        onClose={this.handleClose}>
                        <Modal.Header>
                          Confirm action
                       </Modal.Header>
                        <Modal.Content>
                          {this.state.selectedConsultant &&
                            <p>
                              Are you sure you want to
                              remove <b>{this.state.selectedConsultant.name + " " + this.state.selectedConsultant.surname}</b> from this
                              project?</p>
                          }
                        </Modal.Content>
                        <Modal.Actions>
                          <Button color='grey' onClick={this.handleClose}>Cancel</Button>
                          <Button onClick={() => this.handleRemoveConsultant(this.state.selectedConsultant)}>Yes</Button>
                        </Modal.Actions>
                      </Modal>
                    }

                  </div>}</>
                }

              </Segment>
            </React.Fragment>
          )}
        </Can>
      </Container >
    );
  }
}

function mapStateToProps(state) {
  return {
    user_groups: state.authReducer.user_groups,
    loading: state.projectReducer.loading,
    projects: state.projectReducer.projects,
    project: state.projectReducer.project,
    consultants: state.projectReducer.project_consultants,
    loading_project_corp: state.projectReducer.loading_corporate,
    loading_consultant_corp: state.projectReducer.loading,
    consultantsData: state.manageConsultantsReducer.consultants,
    loading_consultant: state.manageConsultantsReducer.loading,
    loading_timereport: state.projectReducer.loading_timereport,

    companies: state.companyReducer.companies,

    options: {
      project_type: _.toArray(
        _.mapValues(_.filter(state.projectTypeReducer.projecttypes, { 'active': 1, 'corporate': 1 }), o => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            type: o.type
          };
        })),

      manager: _.toArray(
        _.mapValues(state.project_managerReducer.project_manager, o => {
          return {
            key: o.unique_id,
            value: o.unique_id,
            text: o.surname + " " + o.name + " - CN " + o.cn_id
          };
        })
      ),
      type: _.toArray(
        _.mapValues(state.projectTypeReducer.projecttypes, o => {
          return {
            key: o.id,
            value: o.id,
            text: o.name
          };
        })
      ),
      company: _.toArray(
        _.mapValues(_.filter(state.companyReducer.companies, { 'active': 1 }), o => {
          return {
            key: o.id,
            value: o.id,
            text: o.name
          };
        })
      ),
      billing_customer: _.toArray(
        _.mapValues(state.customerReducer.customers, o => {
          return {
            key: o.id,
            value: o.id,
            text: o.name
          };
        })
      ),
      final_customer: _.toArray(
        _.mapValues(state.customerReducer.customers, o => {
          return {
            key: o.id,
            value: o.id,
            text: o.name
          };
        })
      ),
      currency: _.toArray(
        _.mapValues(state.currencyReducer.currencies, o => {
          return {
            key: o.id,
            value: o.id,
            text: o.code
          };
        })
      )
    },

    consultant_options: _.toArray(
      _.mapValues(state.manageConsultantsReducer.consultants, o => {
        return {
          key: o.id,
          value: o.id,
          title: o.name + " " + o.surname + " (" + o.cn_code + ")",
        };
      }),
    ),



    loadings: {
      invoice_type: state.invoiceTypeReducer.loading,
      project_type: state.projectTypeReducer.loading,
      company: state.companyReducer.loading
    },

    gdpr_form:state.projectReducer.gdpr_form,
    gdpr_answers:state.projectReducer.gdpr_answers,

    loading_GDPR_form: {
      gdpr: state.projectReducer.loading_gdpr_form
    },

    risk_eval_assets: state.projectReducer.risk_eval_assets,
    risk_eval_assets_details: state.projectReducer.risk_eval_assets_details,
    risk_eval_survey: state.projectReducer.risk_eval_survey,

    loading_risk_eval_form: {
      risk_eval: state.projectReducer.loading_risk_eval_form
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectDetails: (projectId) => dispatch(startGetCorporateProject(projectId)),
    getCompanies: () => dispatch(startGetCompanies("CREATE_CORPORATE")),
    getCorporateProjects: () => dispatch(startGetCorporateProjects()),
    createCorporateProject: (params, onSuccess, onFailure) => dispatch(startCreateCorporateProject(params, onSuccess, onFailure)),
    getProjectTypes: () => dispatch(startGetProjectTypes("READ_CORPORATE")),
    toggleTimeReporting: (projectId, status) => dispatch(startToggleTimeReporting(projectId, status, true)),
    getConsultantToCorporate: (projectID) => dispatch(startGetProjectConsultants(projectID)),
    editProject: (projectID, params, onSuccess, onFailure) => dispatch(startEditCorporateProject(projectID, params, onSuccess, onFailure)),
    searchConsultants: (params) => dispatch(startSearchAllConsultant(params)),
    resetSearch: () => dispatch(resetSearch()),
    addConsultantToCoporate: (projectId, params, onSuccess, onFailure) => dispatch(startCreateConsultantToCorporate(projectId, params, onSuccess, onFailure)),
    removeConsultantFromProject: (projectId, consultantId, onSuccess, onFailure) => dispatch(startRemoveConsultantFromCorporate(projectId, consultantId, onSuccess, onFailure)),
    getGDPRForm: (project_id) => dispatch(startGetGDPRForm(project_id)),
    submitGDPR: (project_id, answers, is_corporate) => dispatch(startSubmitGDPRForm(project_id, answers, is_corporate)),
    updateGDPRAnswers: (project_id, answers, is_corporate) => dispatch(startUpdateGDPRAnswers(project_id, answers, is_corporate)),
    GetGDPRAnswers: (project_id) => dispatch(startGetGDPRAnswers(project_id)),

    getRiskEvalAsset: (project_id) => dispatch(startGetRiskEvalAssets(project_id)),
    getRiskEvalAssetsDetails: (project_id, assets_code) => dispatch(startGetRiskEvalAssetsDetails(project_id, assets_code)),
    getRiskEvalSurvey: (project_id, main_asset_code) => dispatch(startGetRiskEvalSurvay(project_id, main_asset_code)),
    submitRiskEvalSurvey: (project_id, answers, is_corporate) => dispatch(startSubmitRiskEvalSurvey(project_id, answers, is_corporate)),
    submitRiskEvalForm: (project_id, vulnerabilities, is_corporate) => dispatch(startSubmitRiskEvalForm(project_id, vulnerabilities, is_corporate)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorporateProjectsContainer);
